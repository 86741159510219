import React from "react";
import {AppMenu} from "./AppMenu";
import './home.less'
import {AppContent} from "./AppContent";
import {AppHead} from "./AppHead";

export const AppHome = () => (
    <div className="app-home">
        <AppContent/>
        <AppHead/>
        <AppMenu/>
    </div>
)