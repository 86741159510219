import React, { ReactNode } from 'react';
import { isElement, isFragment } from 'react-is';
import { O2Markdown } from 'src/home/markdown/Markdown';

const isHTMLString = RegExp.prototype.test.bind(/<\/([A-Z]|div|span|[bpi]|a|li)>|&(nbsp|[lg]t);/);

interface RenderTable {
  (
    options: {
      code: string;
      fields: string[];
      headers: string[];
      content: Record<string, any>[];
    },
    key?: number | string
  ): ReactNode;
}
const renderTable: RenderTable = (options, key) => {
  const { headers, content, fields, code } = options;
  const header = (
    <tr className="pl-markdown-table-head-row">
      {headers.map((header, index) => (
        <th
          className={`pl-markdown-table-head-cell pl-markdown-table-cell-${fields[index]}`}
          key={header}
        >
          {header}
        </th>
      ))}
    </tr>
  );
  const renderCell = (cell: { required: boolean; field: string; data: any }) => {
    const { required, field, data } = cell;
    const isRequired = field === 'name' && required;
    const isString = typeof data === 'string';
    const isHTML = isString && isHTMLString(data);
    let content: ReactNode;
    if (isElement(data)) content = data;
    else if (isHTML) {
      content = <div className="pl-markdown" dangerouslySetInnerHTML={{ __html: data }} />;
    } else if (isString) content = <O2Markdown md={data} />;
    else content = <div className="pl-markdown">{data}</div>;
    if (isRequired) {
      return (
        <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
          <div style={{ color: 'red', fontSize: '12px' }}>[必须]&nbsp;</div>
          {content}
        </div>
      );
    } else return content;
  };
  const body = content.map((item, index) => (
    <tr className="pl-markdown-table-row" key={index}>
      {fields.map((field) => {
        return (
          <td className={`pl-markdown-table-cell pl-markdown-table-cell-${field}`} key={field}>
            {renderCell({ required: item.required, field, data: item[field] })}
          </td>
        );
      })}
    </tr>
  ));
  return (
    <div key={key ? `doc-table${key}` : key} className="pl-markdown">
      <table className={`pl-markdown-table pl-markdown-table-${code}`}>
        <thead>{header}</thead>
        <tbody>{body}</tbody>
      </table>
    </div>
  );
};
export default renderTable;
