import {classnames, computed, reactive, SimpleFunction, useRefs} from "o2-design";
import React, {ReactNode} from "react";
import {TokenService} from "../request/token";
import {debounce} from "o2-design/src/utils/debounce";
import {AppPage} from "./AppPage";
import {delay} from "plain-utils/utils/delay";
import MenuData from '../data.menus'

export type AppRoute = { path: string, hash: string }
export type AppNavigatorListener = (route: AppRoute) => void

export const buildHref = ({path, hash}: AppRoute) => encodeURIComponent(`${path}${!!hash ? '#' + hash : ''}`)

export const nav = (() => {

    const initializeNavigation = (() => {
        const tasks = [] as SimpleFunction[]
        const registry = (task: SimpleFunction) => {tasks.push(task)}
        const run = () => {tasks.forEach(i => i())}
        return {
            registry, run,
        }
    })();

    /**
     * 默认打开路径
     * @author  韦胜健
     * @date    2021/5/18 15:41
     */
    const DEFAULT_PATH = MenuData[0].children[0].path

    /**
     * 格式化url
     * @author  韦胜健
     * @date    2021/4/24 22:15
     */
    const urlFormatter = (() => {
        const map: Record<string, string> = {}
        return (path: string) => {
            if (!!map[path]) {return map[path]}
            let newPath = path
            if (newPath.charAt(0) === '/') {newPath = newPath.slice(1)}
            if (newPath.charAt(newPath.length - 1) === '/') {newPath = newPath.slice(0, -1)}
            map[path] = newPath
            return newPath
        }
    })();

    const getRoute = (() => {
        /*可能会在一次js执行中多次获取route，这里做缓存，如果window.location.href变了的话，就重新计算*/
        let prevHref: string, prevRoute: AppRoute;
        return (): AppRoute => {
            if (window.location.href === prevHref) {return prevRoute!}
            let uri = decodeURIComponent(window.location.hash || '')
            if (uri.charAt(0) === '#' && uri.length > 0) {
                uri = uri.substring(1)
            }
            let [path, hash] = uri.split('#')
            if (!!path && path.charAt(0) === '/') {
                path = path.slice(1)
            }
            if (!path) {path = DEFAULT_PATH}
            let route = {path, hash}
            route = TokenService.getPrevPageFromTokenInfo(route) || {path: DEFAULT_PATH, hash: ''}
            route = {
                path: urlFormatter(route.path),
                hash: route.hash,
            }
            prevHref = window.location.href
            prevRoute = route
            return route
        }
    })();

    const listener = {
        pathChange: [] as (AppNavigatorListener)[],
        hashChange: [] as (AppNavigatorListener)[],
        pageReady: [] as (AppNavigatorListener)[],
    }

    const state = reactive({
        route: getRoute(),
    })

    const methods = {
        go: (path: string, hash?: string, blank?: boolean) => {
            blank ? window.open(`#/${path}/`) : window.location.hash = buildHref({path, hash: hash || ''})
        },
        event: {
            on: {
                onPathChange: (cb: AppNavigatorListener) => {
                    listener.pathChange.push(cb)
                    return () => listener.pathChange.splice(listener.pathChange.indexOf(cb), 1)
                },
                onHashChange: (cb: AppNavigatorListener) => {
                    listener.hashChange.push(cb)
                    return () => listener.hashChange.splice(listener.hashChange.indexOf(cb), 1)
                },
                onPageReady: (cb: AppNavigatorListener) => {
                    listener.pageReady.push(cb)
                    return () => listener.pageReady.splice(listener.pageReady.indexOf(cb), 1)
                },
            },
            emit: {
                onPathChange: (route?: AppRoute) => {
                    route = route || getRoute()
                    listener.pathChange.forEach(cb => cb(route!))
                },
                onHashChange: (route?: AppRoute) => {
                    route = route || getRoute()
                    listener.hashChange.forEach(cb => cb(route!))
                },
                onPageReady: (route?: AppRoute) => {
                    route = route || getRoute()
                    listener.pageReady.forEach(cb => cb(route!))
                },
            },
        },
    }

    const render = (() => {
        const renderState = reactive({
            handlers: [] as ((path: string) => Promise<ReactNode>)[],
            renderContent: () => null as any,
            navList: [] as { top: number, name: string }[],
            scrollTop: 0,
        })

        /**
         * 当前锚点索引
         * @author  韦胜健
         * @date    2020/9/17 11:04
         */
        const activeNavIndex = computed(() => {
            for (let i = renderState.navList.length - 1; i >= 0; i--) {
                const navItem = renderState.navList[i];
                if (renderState.scrollTop >= navItem.top) {
                    return i
                }
            }
        })

        const registry = (handler: (path: string) => Promise<ReactNode>, unshift = false) => {
            renderState.handlers[unshift ? 'unshift' : 'push'](handler)
        }
        const renderContent = () => renderState.renderContent()

        const renderNavList = () => (
            <ul className="doc-home-anchor-list" key={renderState.navList.map(i => i.name).join('.')}>
                {renderState.navList.map((item, index) => (
                    <li key={index} className={classnames({'doc-home-anchor-active': index === activeNavIndex.value})}
                        onClick={() => renderHandler.onClickNavItem(item.name)}>
                        <div className="doc-home-anchor-tag">
                            <i className="doc-home-anchor-tag-inner"/>
                        </div>
                        <span className="doc-home-anchor-index">{index + 1}、</span>
                        <span>{item.name}</span>
                    </li>
                ))}
            </ul>
        )

        const {refs, onRef} = useRefs({el: HTMLDivElement})

        const renderMethods = {
            scrollTop: (top: number) => {refs.el!.scrollTop = top},
            /**
             * 刷新锚点扫描数组
             * @author  韦胜健
             * @date    2020/9/17 11:04
             */
            refreshNavList: debounce(() => {
                if (!refs.el) {return}
                const h2List = Array.from(refs.el.querySelectorAll('h2')) as HTMLElement[]
                renderState.navList = h2List.map((h1, index) => {
                    let {offsetTop, innerText} = h1
                    innerText = innerText.replace(/在线调试.*/, '').trim()
                    return {
                        top: index === 0 ? 0 : offsetTop,
                        name: innerText,
                    }
                }).filter(item => !!item.name)
                renderMethods.scrollByHash(getRoute().hash)
            }, 100, false),
            /**
             * 根据锚点定位
             * @author  韦胜健
             * @date    2020/9/17 11:15
             */
            scrollByHash: (hash: string) => {
                if (!hash) {
                    if (!!renderState.navList && renderState.navList.length > 0) {
                        hash = renderState.navList[0].name
                    }
                }
                // 页面锚点定位
                for (let i = 0; i < renderState.navList.length; i++) {
                    const navItem = renderState.navList[i];
                    if (navItem.name === hash) {
                        return renderMethods.scrollTop(navItem.top + 1 - (60 + 16))
                    }
                }
            }
        }

        const renderHandler = {
            /**
             * 处理点击锚点触发定位事件
             * @author  韦胜健
             * @date    2020/9/17 11:04
             */
            onClickNavItem: (name: string) => {
                const {path, hash} = state.route
                if (name === hash) {
                    renderMethods.scrollByHash(state.route.hash)
                } else {
                    methods.go(path, name)
                }
            },
            onPageReady: () => {
                // renderMethods.refreshNavList()
                if (!state.route.hash) {
                    renderMethods.scrollTop(0)
                }
            },
            onPageMounted: async () => {
                await delay(200)
                methods.event.emit.onPageReady()
            },
            /**
             * 处理由时序问题引发的右侧目录错误问题
             * @author  尹书延
             * @date    2023/2/2 14:45
             */
            onRenderContentChange: async () => {
                await delay(120)
                renderMethods.refreshNavList()
            },
        }

        initializeNavigation.registry(() => {

            methods.event.on.onPathChange(debounce(async ({path}) => {
                for (let i = 0; i < renderState.handlers.length; i++) {
                    const handler = renderState.handlers[i];
                    try {
                        const content = await handler(path)
                        renderState.renderContent = () => (
                            <AppPage key={state.route.path} onMounted={renderHandler.onPageMounted}>
                                {content}
                            </AppPage>
                        )
                        renderHandler.onRenderContentChange();
                        break
                    } catch (e) {/*do nothing*/}
                }
            }, 100))

            methods.event.on.onPageReady(renderHandler.onPageReady)

            methods.event.on.onHashChange((route: AppRoute) => renderMethods.scrollByHash(route.hash))

            function findLinkParent(el: HTMLElement | null): null | HTMLElement {
                while (!!el && el.tagName !== 'A') {
                    el = el.parentElement
                }
                return el
            }

            window.addEventListener('click', (e: MouseEvent) => {
                const linkEl = findLinkParent(e.target as HTMLElement)
                if (!!linkEl) {
                    const href = linkEl.getAttribute('href')
                    if (!!href) {
                        const match = href.match(/(?:~|^#)(.*)/)
                        // console.log({ href, match })
                        if (!!match) {
                            let [, path] = match
                            path = decodeURIComponent(path)
                            methods.go(path)
                        } else if (href.indexOf('TYPE ') === 0) {
                            methods.go(state.route.path, href)
                        } else {
                            window.open(href)
                        }
                    }
                    e.preventDefault()
                    // e.stopPropagation()
                    return
                }
                const el = e.target as HTMLElement
                if (el.tagName === 'H2' || el.parentElement!.tagName === 'H2') {
                    if (el.tagName === 'H2' && !!el.firstElementChild && el.firstElementChild.tagName === 'SPAN') {
                        return;
                    }
                    let anchorEl = el.tagName === 'H2' ? el : el.parentElement!
                    const span = anchorEl.querySelector('span')
                    const text = !!span ? span.innerText : anchorEl.innerText
                    methods.go(state.route.path, text)
                }
            }, true);

            !!render.refs.el && render.refs.el.addEventListener('scroll', () => {
                renderState.scrollTop = refs.el!.scrollTop
            })

        })

        return {
            registry,
            renderContent,
            renderNavList,
            onRef,
            refs,
            renderHandler,
        }
    })();

    initializeNavigation.registry(() => {
        window.addEventListener('hashchange', () => {
            const route = getRoute()
            const {path, hash} = route
            if (path !== state.route.path) {
                methods.event.emit.onPathChange(route)
            } else if (hash !== state.route.hash) {
                methods.event.emit.onHashChange(route)
            }
            state.route = route
        })
        methods.event.emit.onPathChange(state.route)
    })

    setTimeout(() => window.location.href.indexOf('debug.html') === -1 && initializeNavigation.run())

    return {
        state,
        methods,
        getRoute,
        isActive: (path: string) => urlFormatter(state.route.path) === urlFormatter(path),
        render,
    }
})();


