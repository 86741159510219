import React, {ReactNode} from "react";
import {isElement, isFragment} from 'react-is'
import {O2Markdown} from "../../markdown/Markdown";
import renderTable from "./renderTable";

const createPropComponent = () => (() => null) as React.FC<{ children: string }>

interface iItemProps {
    name?: string,
    type?: string,
    default?: string,
    required?: boolean,
    desc?: string,
    version?: string,
    children: any,
}

type columnCode = Exclude<keyof iItemProps, 'children'>

export const JsxDocUtils = (() => {

    const registration = {
        Title: createPropComponent(),
        Desc: createPropComponent(),
        Foot: createPropComponent(),
        Name: createPropComponent(),
        Type: createPropComponent(),
        Default: createPropComponent(),
        Item: createPropComponent(),
    }

    function registry(name: string, cls: string, columns: ([string, columnCode])[]) {
        (registration as any)[name] = (props: any) => {
            let {children, ...leftProps} = props
            const data = {
                ...leftProps,
                items: [] as iItemProps[],
            }
            if (isFragment(children)) {children = children.props.children}
            if (!Array.isArray(children)) {children = [children]}
            (children as ReactNode[]).forEach(child => {
                if (!isElement(child)) {
                    return console.warn('Props的children的每一个子元素必须为ReactElement', child)
                }
                if (child.type === registration.Title) {
                    return data.title = child.props.children
                }
                if (child.type === registration.Desc) {
                    return data.desc = child.props.children
                }
                if (child.type === registration.Foot) {
                    return data.foot = child.props.children
                }
                if (child.type === registration.Item) {
                    let {children: itemChildren, ...itemProps} = child.props as iItemProps
                    data.items.push(itemProps as any)
                    if (isFragment(itemChildren)) {itemChildren = itemChildren.props.children}
                    if (!Array.isArray(itemChildren)) {itemChildren = [itemChildren]}

                    itemChildren.forEach((child: ReactNode) => {
                        if (!child) {return }
                        if (!isElement(child)) {
                            return console.warn('Item的children的每一个子元素必须为ReactElement', child)
                        }
                        if (child.type === registration.Name) {
                            itemProps.name = child.props.children
                        }
                        if (child.type === registration.Type) {
                            itemProps.type = child.props.children
                        }
                        if (child.type === registration.Default) {
                            itemProps.default = child.props.children
                        }
                        if (child.type === registration.Desc) {
                            itemProps.desc = child.props.children
                        }
                    })
                }
            });

            const {titles, fields} = columns.reduce((prev, item) => {
                prev.titles.push(item[0])
                prev.fields.push(item[1])
                return prev
            }, {titles: [] as string[], fields: [] as string[]})
            // console.log({code: cls, headers: titles, fields, content: data.items});
            return (
                <div className="pl-mdc-card">
                    <div className={`pl-mdc-table pl-mdc-table-${cls}`}>
                        <h2><span>API:{data.title}</span></h2>
                        {!!data.desc && <O2Markdown md={data.desc}/>}
                        {renderTable({code: cls, headers: titles, fields, content: data.items})}
                    </div>
                </div>
            )
        }
    }

    return {
        registry,
        registration,
    }
})();

JsxDocUtils.registry('Props', 'props', [
    ['属性名', 'name'],
    ['类型', 'type'],
    ['默认值', 'default'],
    ['描述', 'desc'],
    ['版本', 'version'],
])
JsxDocUtils.registry('Events', 'events', [
    ['事件名', 'name'],
    ['类型', 'type'],
    ['描述', 'desc'],
])
JsxDocUtils.registry('Methods', 'methods', [
    ['方法名', 'name'],
    ['类型', 'type'],
    ['描述', 'desc'],
])
JsxDocUtils.registry('Slots', 'slots', [
    ['插槽名称', 'name'],
    ['描述', 'desc'],
])
JsxDocUtils.registry('ScopeSlots', 'scope-slots', [
    ['作用域插槽名称', 'name'],
    ['类型', 'type'],
    ['描述', 'desc'],
])
JsxDocUtils.registry('Refs', 'refs', [
    ['引用名称', 'name'],
    ['类型', 'type'],
    ['描述', 'desc'],
])