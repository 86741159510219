import {computed, designComponent} from "o2-design";
import React from "react";
import {processMdc} from "./process/processMdc";
import {O2Markdown} from "../markdown/Markdown";
import {isMdcTable} from "./process/processTableString";
import {MdcCaseComponent} from "./MdcCase";
import './mdc.less'
import {MdcJsxDocComponent} from "./MdcDoc";

export const Mdc = designComponent({
    props: {
        content: {type: String, required: true},
    },
    setup({props}) {

        const mdcList = computed(() => processMdc(props.content))

        return () => (
            <div className="pl-mdc">
                {mdcList.value.map((mdc, index) => typeof mdc === "string" ? (
                    <div className="pl-mdc-card" key={index}>
                        <O2Markdown md={mdc}/>
                    </div>
                ) : (() => {
                    if (isMdcTable(mdc)) {
                        return (
                            <div className="pl-mdc-card" key={index}>
                                {/*<MdcTableComponent mdc={mdc}/>*/}
                            </div>
                        )
                    }
                    if ('jsx' in mdc) {
                        return (
                            <div className="pl-mdc-card" key={index}>
                                <MdcCaseComponent mdc={mdc}/>
                            </div>
                        )
                    }
                    if ('render' in mdc) {
                        return (
                            <div className="pl-mdc-card" key={index}>
                                {mdc.render()}
                            </div>
                        )
                    }
                    return (
                        <MdcJsxDocComponent mdc={mdc as any}/>
                    )
                })())}
            </div>
        )
    },
})
