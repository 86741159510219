export interface MdcCase {
    title?: string,             // 示例标题
    desc?: string,              // 示例描述
    bottomDesc?: string,        // 示例底部描述
    content?: string,           // 示例内容描述(在折叠代码内部)
    jsx?: string
    css?: string
    index: number,              // mdc的位置索引
    expand: boolean,            // 示例是否默认展开代码
    space?: boolean,            // 示例中的对象需要margin间隔
}

/**
 * 解析CaseString
 * @author  韦胜健
 * @date    2020/9/16 9:53
 */
export function processCaseString(caseString: string, index?: number): MdcCase {

    const ret: MdcCase = {
        title: '',
        desc: '',
        content: '',
        index: index!,
        expand: false,
    }

    let match: RegExpMatchArray | null;

    /*---------------------------------------jsx-------------------------------------------*/

    match = caseString.match(/```jsx((?:.|\s)*?)```/)
    if (!!match) {
        ret.jsx = match[1].trim()
        caseString = caseString.replace(match[0], '').trim()
    }
    /*---------------------------------------css-------------------------------------------*/

    match = caseString.match(/```css((?:.|\s)*?)```/)
    if (!!match) {
        ret.css = match[1].trim()
        caseString = caseString.replace(match[0], '').trim()
    }

    /*---------------------------------------title-------------------------------------------*/

    match = caseString.match(/title(?::|：)(.*)/)
    if (!!match) {
        ret.title = match[1]
        caseString = caseString.replace(match[0], '').trim()
    }

    /*---------------------------------------inlineSpace-------------------------------------------*/

    match = caseString.match(/.*(\n|\r|\s)*space(?::|：)(.*)/)
    if (!!match) {
        ret.space = match[2] === 'true'
        caseString = caseString.replace(match[0], '').trim()
    }
    /*---------------------------------------expand-------------------------------------------*/

    match = caseString.match(/expand(?::|：)(.*)/)
    if (!!match) {
        ret.expand = match[1] === 'true'
        caseString = caseString.replace(match[0], '').trim()
    }

    /*---------------------------------------desc-------------------------------------------*/

    match = caseString.match(/<desc>((?:.|\r|\n|\t)*)<\/desc>/)
    if (!!match) {
        ret.desc = match[1].trim()
        caseString = caseString.replace(match[0], '').trim()
    } else {
        match = caseString.match(/desc(?::|：)(.*)/)
        if (!!match) {
            ret.desc = match[1].trim()
            caseString = caseString.replace(match[0], '').trim()
        }
    }
    /*---------------------------------------bottomDesc-------------------------------------------*/

    match = caseString.match(/<bottomDesc>((?:.|\r|\n|\t)*)<\/bottomDesc>/)
    if (!!match) {
        ret.bottomDesc = match[1].trim()
        caseString = caseString.replace(match[0], '').trim()
    } else {
        match = caseString.match(/bottomDesc(?::|：)(.*)/)
        if (!!match) {
            ret.bottomDesc = match[1].trim()
            caseString = caseString.replace(match[0], '').trim()
        }
    }

    /*---------------------------------------left content-------------------------------------------*/

    ret.content = caseString.trim()

    return ret
}

export const MATCH_CASE_STRING = /:::case((?:.|\s)*?):::/g

/**
 * 单独获取某个case对象
 * @author  韦胜健
 * @date    2020/9/18 20:17
 */
export function getSingleMdcCase(mdcString: string, countIndex: number) {
    const stringList = [] as string[]
    mdcString!.replace(MATCH_CASE_STRING, (...args) => {
        stringList.push(args[1].trim())
        return ''
    })
    return processCaseString(stringList[countIndex], countIndex)
}
