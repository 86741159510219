import DefaultO2PageHead from "o2-design/src/packages/O2Page/DefaultO2PageHead";
import DefaultO2PageContent from "o2-design/src/packages/O2Page/DefaultO2PageContent";
import React from "react";
import {createO2PageDesigner} from "o2-design";

const back = (backPath: string) => {
    window.history.pushState({}, '', backPath)
}

const designO2Page = createO2PageDesigner({
    renderHead: (props) => <DefaultO2PageHead title={props.title} backPath={props.backPath} operator={props.operator} onClickBack={back}/>,
    renderContent: (content) => <DefaultO2PageContent>{content}</DefaultO2PageContent>,
})

export default designO2Page
