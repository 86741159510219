import useTableOption from './useTableOption';

/*eslint-disable*/

const useObjectOption: typeof useTableOption = (config) => {

    /*默认情况下不可新建、编辑以及删除*/
    if (config.enable == null) {config.enable = {insert: false, update: false, delete: false};}
    if (typeof config.enable === 'object') {
        config.enable.insert == null && (config.enable.insert = false);
        config.enable.update == null && (config.enable.update = false);
        config.enable.delete == null && (config.enable.delete = false);
    }

    return useTableOption(config);
};

export default useObjectOption

/* eslint-enable */
