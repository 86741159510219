import React from "react";

export const MATCH_JSX_DOC_STRING = /```jsx doc((?:.|\s)*?)```/g

export interface MdcJsxDoc {
    jsxDoc: string,
}

export function processJsxDocString(content: string) {
    return {
        jsxDoc: content
    }
}
