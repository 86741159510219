import {notification} from 'choerodon-ui';
import {tGetDefaultUrlConfig} from 'o2-design';
import http from "./http";
import {mockData} from "./mock.data";

/* eslint-disable */
function pathJoin(
    prepend: string | null | undefined | number,
    append: string | null | undefined | number
): string {
    if (prepend == null) {
        prepend = '';
    }
    if (typeof prepend !== 'string') {
        prepend = String(prepend);
    }
    if (prepend.charAt(prepend.length - 1) === '/') {
        prepend = prepend.slice(0, -1);
    }
    if (append == null) {
        append = '';
    }
    if (typeof append !== 'string') {
        append = String(append);
    }
    if (append.charAt(0) === '/') {
        append = append.slice(1);
    }
    return `${prepend}/${append}`;
}

export const getDefaultUrlConfig: tGetDefaultUrlConfig = {
    query: (config) => {
        let {url, base, method, request, ...left} = config;
        if (!url && !!base) {
            url = base;
        }
        if (!method) {
            method = 'GET';
        }
        if (!request) {
            request = async (requestConfig) => {
                if (requestConfig.url === ' ') {return mockData(requestConfig)}

                try {
                    const {url, query, body, ...config} = requestConfig;
                    const respData = await http.request(url, {
                        ...config,
                        query,
                        body,
                    });
                    if (!respData) {
                        throw new Error('查询失败！');
                    }
                    if (respData.failed === true) {
                        throw new Error(respData.message!);
                    }
                    return respData;
                } catch (e) {
                    notification.error({message: '查询失败！', description: String(e)});
                    throw e;
                }
            };
        }
        return {...left, url: url!, method, request};
    },
    detail: (config, keyField) => {
        let {url, base, method, request, ...left} = config;
        // if (!url && !!base) {url = pathJoin(base, '这是一个id')}
        if (!method) {
            method = 'GET';
        }
        if (!request) {
            request = async (requestConfig) => {
                if (requestConfig.url === ' ') {return mockData(requestConfig)}

                try {
                    let {url, query, body, method, ...config} = requestConfig;
                    const requestData = method.toUpperCase() === 'GET' ? query : body;
                    if (!url && !!base) {
                        url = pathJoin(base, requestData[keyField]);
                    }
                    const {_resp, ...respData} = await http.request(url, {
                        ...config,
                        query,
                        body,
                    });
                    if (!respData) {
                        throw new Error('查询详情失败！');
                    }
                    if (respData.failed === true) {
                        throw new Error(respData.message!);
                    }
                    return respData;
                } catch (e) {
                    notification.error({message: '查询详情失败！', description: String(e)});
                    throw e;
                }
            };
        }
        return {...left, url: url!, method, request};
    },
    insert: (config) => {
        let {url, base, method, request, ...left} = config;
        if (!url && !!base) {
            url = base;
        }
        if (!method) {
            method = 'POST';
        }
        if (!request) {
            request = async (requestConfig) => {
                if (requestConfig.url === ' ') {return mockData(requestConfig)}

                try {
                    const {url, query, body, ...config} = requestConfig;
                    const data = await http.request(url, {
                        ...config,
                        url,
                        query,
                        body,
                    });
                    if (!data) {
                        throw new Error('新建失败！');
                    }
                    if (data.failed === true) {
                        throw new Error(data.message || data.detailsMessage);
                    }
                    return {
                        newRow: data,
                        ...data,
                    };
                } catch (e) {
                    notification.error({message: '新建失败！', description: String(e)});
                    throw e;
                }
            };
        }
        return {...left, url: url!, method, request};
    },
    update: (config) => {
        let {url, base, method, request, ...left} = config;
        if (!url && !!base) {
            url = base;
        }
        if (!method) {
            method = 'PUT';
        }
        if (!request) {
            request = async (requestConfig) => {
                if (requestConfig.url === ' ') {return mockData(requestConfig)}
                try {
                    const {url, query, body, ...config} = requestConfig;
                    const data = await http.request(url, {
                        url,
                        ...config,
                        query,
                        body,
                    });
                    if (!data) {
                        throw new Error('保存失败！');
                    }
                    if (data.failed === true) {
                        throw new Error(data.message || data.detailsMessage);
                    }
                    return {
                        newRow: data,
                        ...data,
                    };
                } catch (e) {
                    notification.error({message: '更新失败！', description: String(e)});
                    throw e;
                }
            };
        }
        return {...left, url: url!, method, request};
    },
    delete: (config) => {
        let {url, base, method, request, ...left} = config;
        if (!url && !!base) {
            url = base;
        }
        if (!method) {
            method = 'DELETE';
        }
        if (!request) {
            request = async (requestConfig) => {
                if (!requestConfig.url || requestConfig.url === ' /delete') {return mockData(requestConfig)}
                try {
                    const {url, query, body, ...config} = requestConfig;
                    const data = await http.request(url, {
                        ...config,
                        query,
                        body,
                    });
                    if (!!data.message) {
                        throw notification.error({message: '删除失败！', description: data.message});
                    }
                } catch (e) {
                    notification.error({message: '删除失败！', description: String(e)});
                    throw e;
                }
            };
        }
        return {...left, url: url!, method, request};
    },
};

/* eslint-enable */
