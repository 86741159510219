import React, { ReactNode } from 'react';
import { isElement, isFragment } from 'react-is';
import { O2Markdown } from 'src/home/markdown/Markdown';
import renderTable from '../jsxdoc/renderTable';
import { registryProcessor } from './processMdc';

export interface MdcRendered {
  render: () => ReactNode;
}

const renderDesc = (str: string, key?: number | string) => (
  <div key={key ? `doc-desc${key}` : key} className="pl-markdown">
    <O2Markdown md={str} />
  </div>
);
/** 在名称字段最前面填写`[必填]`或`*`，渲染必填样式 */
const isRequiredReg = /^\s*[{([{（【]?(?:必[填选需须]|required?|\*)[\])}）】]?/i;
/** 获取表格中使用的管道符`\|`->`|` */
const pipeOperatorReg = /\\\|/g;
/** 表格中使用的管道符`\|`临时替换为这个 */
const pipeOperatorHandler = '【pipeOperatorHandler】';
/** 解析结束后替换回管道符`|` */
const pipeOperatorHandlerReg = /【pipeOperatorHandler】/g;
const IDLE = 0;
const CONTENT = 1;
const TABLE = 2;
const getLineData = (line: string, fields: string[]) => {
  const lineReg = /\|((?:[^|])*)(?!\s*$)/g;
  const res: string[] = [];
  let safe = fields.length + 1;
  let arr: RegExpExecArray | null;
  line = line.replace(pipeOperatorReg, pipeOperatorHandler);
  // console.log(line);
  while ((arr = lineReg.exec(line)) !== null && safe-- > 0) {
    // console.log(arr[1]);
    res.push(arr[1].trim().replace(pipeOperatorHandlerReg, '|'));
  }
  const obj: Record<string, any> = {};
  fields.forEach((field, index) => {
    if (field === 'name' && isRequiredReg.test(res[index])) {
      obj.required = 1;
      res[index] = res[index].replace(isRequiredReg, '');
    }
    if (res[index]) obj[field] = res[index];
  });
  return obj;
};
interface RenderContent {
  (options: { content: string; code: string; columns: [string, string][] }): ReactNode;
}
const renderContent: RenderContent = ({ content, code, columns }) => {
  let key = 0;
  const headers = columns.map(([name]) => name);
  const fields = columns.map(([_, field]) => field);
  const lines = content
    .replace(/^\s*title:[^\n]+\n/, '') // 删除 title
    .replace(/<!--[\S\s\n]*?-->/g, '') // 删除注释
    .replace(/\r\n/g, '\n') // 统一换行符
    .split('\n')
    .filter((line) => !/^\s*:::/.test(line));
  // console.log(lines);
  const rendered: ReactNode[] = [];
  const isTableLine = RegExp.prototype.test.bind(/^\s*\|.+\|\s*$/);
  const isTableHead = RegExp.prototype.test.bind(/^\s*\|[\s|!:-]+\|\s*$/);
  let status = IDLE;
  let temp = '';
  let tempTable: Record<string, any>[] = [];
  /** 转移逻辑
   * 空闲状态：初始状态
   * 内容状态：当前的内容渲染为 MD ，切换时渲染 MD
   * 表格状态：当前的内容渲染为表格，切换时渲染表格
   */
  for (const line of lines) {
    // console.log(line, isTableLine(line));
    if (isTableLine(line)) {
      // 遇到表格
      switch (status) {
        case CONTENT: // 内容状态 -> 表格状态
          // console.log('Desc -> Table', temp);
          rendered.push(renderDesc(temp, key));
          temp = '';
        // eslint-disable-next-line no-fallthrough
        case IDLE:
        case TABLE: // 表格状态 添加该行
          if (isTableHead(line)) {
            tempTable = [];
          } else {
            // console.log('getLineData', getLineData(line, fields));
            tempTable.push(getLineData(line, fields));
          }
          break;
      }
      status = TABLE;
    } else if (line.trim() === '') {
      // 遇到空行
      switch (status) {
        case TABLE: // 表格状态 什么都不做
        case IDLE: // 空闲状态 什么都不做
          break;
        // eslint-disable-next-line no-fallthrough
        case CONTENT: // 内容状态 正常加上这一行
          temp += '\n' + line;
          break;
      }
    } else {
      // 遇到普通内容
      switch (status) {
        case TABLE: // 表格状态 -> 内容状态
          // console.log('Table -> Desc', tempTable);
          rendered.push(renderTable({ code, headers, fields, content: tempTable }, key));
          tempTable = [];
        // eslint-disable-next-line no-fallthrough
        case IDLE:
        case CONTENT: // 内容状态 添加该行
          temp += '\n' + line;
          break;
      }
      status = CONTENT;
    }
  }
  switch (status) {
    case CONTENT: // 内容状态 -> 表格状态
      // console.log('renderDesc', temp);
      rendered.push(renderDesc(temp, key));
      temp = '';
      break;
    // eslint-disable-next-line no-fallthrough
    case TABLE: // 表格状态 添加该行
      // console.log('renderTable', tempTable);
      rendered.push(renderTable({ code, headers, fields, content: tempTable }, key));
      tempTable = [];
      break;
  }

  return rendered;
};

export const ComponentDocUtils = (() => {
  interface ComponentDocUtilsRegister {
    (options: { name: string; code: string; columns: [string, string][] }): any;
  }
  const register: ComponentDocUtilsRegister = ({ name, code, columns }) => {
    const REG = new RegExp(`:::\\s?${name}((?:.|\\s)*?):::`, 'ig');
    const processCaseString = (str: string): MdcRendered => {
      let title = (str.match(/^\s*title:\s*([^\n]+?)\s*\n/) || [])[1];
      return {
        render: () => (
          <div className={`pl-mdc-table pl-mdc-table-${code}`}>
            <h2>
              <span>API: {title || ''}</span>
            </h2>
            {renderContent({ content: str, code, columns })}
          </div>
        ),
      };
    };
    registryProcessor(REG, processCaseString);
  };
  return { register };
})();

export const registerComponentDoc = () => {
  ComponentDocUtils.register({
    name: 'Props',
    code: 'props',
    columns: [
      ['属性名', 'name'],
      ['类型', 'type'],
      ['默认值', 'default'],
      ['描述', 'desc'],
      ['版本', 'version'],
    ],
  });
  ComponentDocUtils.register({
    name: 'Slots',
    code: 'slots',
    columns: [['插槽名称', 'name'], ['描述', 'desc']],
  });
  ComponentDocUtils.register({
    name: 'Events',
    code: 'events',
    columns: [['事件名', 'name'], ['类型', 'type'], ['描述', 'desc']],
  });
  ComponentDocUtils.register({
    name: 'Methods',
    code: 'methods',
    columns: [['方法名', 'name'], ['类型', 'type'], ['描述', 'desc']],
  });
  ComponentDocUtils.register({
    name: 'ScopeSlots',
    code: 'scope-slots',
    columns: [['作用域插槽名称', 'name'], ['类型', 'type'], ['描述', 'desc']],
  });
  ComponentDocUtils.register({
    name: 'Refs',
    code: 'refs',
    columns: [['引用名称', 'name'], ['类型', 'type'], ['描述', 'desc']],
  });
};
