import {designPage} from "o2-design";
import React from "react";
import O2DesignPackage from 'o2-design/package.json'
import {nav} from "./nav";

export const AppHead = designPage(() => {

    const topList = [
        {
            name: '产品白皮书',
            link: 'https://open.hand-china.com/document-center/doc/product/10073/10760?_back=/dashboard&doc_id=318419&doc_code=11437',
        },
        {
            name: 'O2技术文档',
            link: 'https://o2ec.yuque.com/lot8pd/o2doc',
        },
        {
            name: 'O2CMS Designer',
            link: '/#/5.其他组件/4.O2CMS Designer_blank/',
        },
        {
            name: 'O2PC UI',
            link: 'https://ui-component.o2demo.hand-china.com/pcui/',
        },
        // {
        //     name: '技术培训',
        //     link: 'https://doc.o2demo.hand-china.com/zh/docs/7-train/',
        // },
        // {
        //     name: '联系我们',
        //     link: 'https://doc.o2demo.hand-china.com/zh/docs/97-appendix/99-share-doc/',
        // },
    ];

    return () => (
        <div className="app-head">
            <div className="app-head-nav">
                {
                    topList.map((item) => {
                        if(item.link.indexOf('blank') > -1){
                            return <a className="nav-item" onClick={()=>window.open(item.link)} >{item.name}</a>
                        }
                        return <a className="nav-item" href={item.link}>{item.name}</a>
                    })
                }
            </div>
            <div className="change-log-link">
                <span onClick={() => nav.methods.go('99.文档工程/5.更新日志')}>更新日志 v{O2DesignPackage.version}</span>
            </div>
        </div>
    )
})
