import {defer, initialize, Intl} from 'o2-design'
import useTableOption from "./useTableOption";
import useFormOption from "./useFormOption";
import useObjectOption from "./useObjectOption";
import designO2Page from './designO2Page'
import http from "./http";
import {iPermissionRespData} from 'o2-design/src/packages/O2Table/utils/PermissionUtils';
import {notification} from 'choerodon-ui';
import { TokenService } from '../request/token';
import {env} from "../env/env";

const organizationId = 2;

initialize(() => ({
	useTableOption,
	useObjectOption,
	useFormOption,
	useHttp: () => http,
	usePersistState: (state: any) => state,
	designO2Page,
	isTenantRoleLevel: () => true,
	/*多语言转换*/
	intl: (() => {
		/**
		 * 自定义多语言转换逻辑
		 * @author  韦胜健
		 * @date    2021/6/30 10:00
		 */
		Intl.init('hzero.common', (code: string) => {
			return Object.assign(code, {
				d: (desc: string) => {
					return desc;
				},
			});
		});
		return Intl;
	})(),
	/*权限获取*/
	checkPermission: async function checkPermission(codes: string[]): Promise<iPermissionRespData[]> {
		return codes.map((code) => {
			// await delay(3000)
			switch (code) {
				case 'o2.o2md.system-parameter-settings.list.insert':
					return { approve: true, code, controllerType: null };
				case 'o2.o2md.system-parameter-settings.list.update':
					return { approve: true, code, controllerType: null };
				case 'o2.o2md.system-parameter-settings.list.delete':
					return { approve: true, code, controllerType: null };
			}
			return { approve: true, code, controllerType: null };
		});
	},
	/*获取值集*/
	getLovAsync: async (lovCode) => {
		const dfd = defer<any>();
		http.get('hpfm/v1/2/lovs/data', { lovCode }).then((data: any) => {
			if (data.failed || data.message) {
				dfd.reject();
				notification.error({ message: '值集加载异常！', description: `值集[${lovCode}]加载失败！` });
			} else {
				dfd.resolve(data);
			}
		}, dfd.reject);
		return dfd.promise;
	},
	/*获取值集视图*/
	getLovViewAsync: (viewCode) => {
		const dfd = defer<any>();
		http.get('/hpfm/v1/2/lov-view/info', { viewCode }).then((data: any) => {
			dfd.resolve(data);
		}, dfd.reject);
		return dfd.promise;
	},

	/*获取多语言编辑数据*/
	getIntlValueAsync: (_token, fieldName) => {
		const dfd = defer<any>();
		http.get('/hpfm/v1/multi-language', _token ? { _token, fieldName } : { fieldName }).then((data: any) => {
			dfd.resolve(data);
		}, dfd.reject);
		return dfd.promise;
	},
	/*批量获取值集 */
	getBatchLovAsync: async (lovCodes) => {
		const dfd = defer<any>();
		http.get('hpfm/v1/2/lovs/value/batch', lovCodes).then((data) => {
			dfd.resolve(data);
		}, dfd.reject);
		return dfd.promise;
	},
	/*获取国家数据 */
	getCountryAsync: async () => {
		const dfd = defer<any>();
		http.get('/o2md-m/v1/2/countries/valid').then((data) => {
			dfd.resolve(data);
		}, dfd.reject);
		return dfd.promise;
	},
	/*获取默认国家编码 */
	getDefaultCountryCodeAsync: async () => {
		const dfd = defer<any>();
		http.get('/o2md-m/v1/2/system-param-values/DEFAULT_COUNTRY_CODE/KV').then((data) => {
			dfd.resolve(data);
		}, dfd.reject);
		return dfd.promise;
	},
	/*根据国家id或者code获取省份数据 */
	getRegionAsync: async (countryCode) => {
		const dfd = defer<any>();
		http.get('/o2md-m/v1/2/regions/valid', { countryCode }).then((data) => {
			dfd.resolve(data);
		}, dfd.reject);
		return dfd.promise;
	},
	/*获取市级数据或县级数据 */
	getCityOrDistrictAsync: async (regionCode) => {
		const param = regionCode instanceof Object ? regionCode : { regionId: regionCode };
		const dfd = defer<any>();
		http.get('/o2md-m/v1/2/regions/valid', param).then((data) => {
			dfd.resolve(data);
		}, dfd.reject);
		return dfd.promise;
	},
	getCurrentOrganizationId: () => 2,
	getCurrentLanguage: () => 'zh_CN',
	getEncryptPassWord: true,
	/* 获取图片上传后的图片数据 */
	getImageDataAsync: async (formData, uploadConfig) => {
		// const dfd = defer<any>();
		// const url = `/o2doc-design/v1/2/media-operation/upload`;
		// http.post(url, formData, { ...uploadConfig }).then((data) => {
		// 	dfd.resolve(data);
		// }, dfd.reject);
		// return dfd.promise;
		// 统一使用cms的接口，不用文档工程的接口
    const dfd = defer<any>();
    const url = `/o2cms-m/v1/2/file/uploads`;
		http.post(url, formData).then((data) => {
			dfd.resolve({ ...data?.files?.[0] });
		}, dfd.reject);
		return dfd.promise;
	},
	/* 获取推荐商品列表 */
	getProductsByGroupCode: async (code: string) => {
		const dfd = defer<any>();
		const url = `o2pcm-m/v1/2/recommend-entrys/cms`;
		http.get(url, { page: 1, size: 10, recommendCode: code }).then((data) => {
			dfd.resolve(data);
		}, dfd.reject);
		return dfd.promise;
	},
	/* 获取OSS文件前缀 */
	getOssPrefix: async () => {
		const dfd = defer<any>();
		const url = `/o2md-m/v1/2/system-parameters/find?paramCode=URL_PREFIX`;
		http.get(url).then((data) => {
			dfd.resolve(data.defaultValue);
		}, dfd.reject);
		return dfd.promise;
	},
	getAccessToken: () => TokenService.state.token?.access_token,
	getScreenSize: () => '',
	// cms组件更新
	queryCmsComponent: async (iconStyle = 'simple') => {
		const dfd = defer<any>();
		const url = `/o2doc-design/v1/${organizationId}/components/all-user-visible`;
		http.get(url, { iconStyle }).then((data) => {
			dfd.resolve(data);
		}, dfd.reject);
		return dfd.promise;
	},
	updateCmsComponent: async (params: any) => {
		const dfd = defer<any>();
		const url = `/o2doc-design/v1/${organizationId}/components`;
		http.post(url, params).then((data) => {
			dfd.resolve(data);
		}, dfd.reject);
		return dfd.promise;
	},
	deleteCmsComponent: async ({ componentId, _token }) => {
		const dfd = defer<any>();
		const url = `/o2doc-design/v1/${organizationId}/components`;
		http.delete(url, { componentId, _token }).then((data) => {
			dfd.resolve(data);
		}, dfd.reject);
		return dfd.promise;
	},
	createCmsComponent: async (params: any) => {
		const dfd = defer<any>();
		const url = `/o2doc-design/v1/${organizationId}/components/create`;
		http.post(url, params).then((data) => {
			dfd.resolve(data);
		}, dfd.reject);
		return dfd.promise;
	},
	getEnvConfig: () => ({ API_HOST: env.baseURL.substring(0, env.baseURL.length - 1) }),
}));
