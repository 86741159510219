import {designComponent, onMounted, reactive, useClasses} from "o2-design";
import React from "react";

export const AppPage = designComponent({
    slots: ['default'],
    setup({slots}) {
        const state = reactive({
            mounted: false,
        })

        const classes = useClasses(() => [
            'app-navigator-page',
            {
                'app-navigator-page-mounted': state.mounted
            }
        ])

        onMounted(() => {
            setTimeout(() => {
                state.mounted = true
            }, 120)
        })

        return {
            render: () => (
                <div className={classes.value}>
                    {slots.default()}
                </div>
            )
        }
    },
})
