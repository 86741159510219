import {nav} from "./home/nav";
import ReactDOM from "react-dom";
import React from "react";
import {ModalProvider} from 'choerodon-ui/pro'
import 'choerodon-ui/dist/choerodon-ui.min.css'
import './o2-design/init'
import './o2-design/c7nUiConfig'
import 'hzero-ui/dist/hzero-ui.css'
import {Mdc} from "./home/mdc/Mdc";

nav.render.registry(async path => {
    // console.log(path)
    if (path.indexOf('.md') === path.length - 3) {
        path = path.slice(0, -3)
    }
    const text = (await import('src/doc/' + path + '.md')).default
    return <Mdc content={text} key={path}/>
})

nav.render.registry(async path => {
    if (path.indexOf('.tsx') === path.length - 3) {
        path = path.slice(0, -3)
    }
    const Default = (await import('src/doc/' + path + '.tsx')).default
    return <div><Default/></div>
})

nav.render.registry(async () => {
    const Page404 = (await import('./home/404/404')).default
    return <Page404/>
})

export function renderEntry(App: any) {
    ReactDOM.render((
        <ModalProvider>
            <App/>
        </ModalProvider>
    ), document.getElementById('app'))
}
