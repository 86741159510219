import JSXModel from 'plain-design-composition-v2/plugins/babel-plugin-react-model.js'
import React from "react";
import {delay} from "plain-utils/utils/delay";
import {deepcopy} from 'plain-utils/object/deepcopy';
import {notification} from "choerodon-ui";
import {Babel} from "../mdc.utils";
import C7NPro from './c7n.exrpot'

/**
 * 解析html字符串
 * @author  韦胜健
 * @date    2020/9/16 9:54
 */
export async function processHtmlString(jsx: string, css?: string) {
    return {
        script: await processScriptString(jsx),
        style: css,
    }

}

async function buildDependency(output: string) {
    let names: string[] = []
    const regexp = /require\(['"](.*)['"]\)/g
    let match = regexp.exec(output)
    while (!!match) {
        names.push(match[1])
        match = regexp.exec(output)
    }
    await delay()
    // const loading = $$loading.bar()
    try {
        const dependencies = (await Promise.all(names.map(async name => {
            if (name === 'react') return {
                name,
                module: await import('react'),
            }
            if (name === 'plain-utils') {
                return {
                    name,
                    module: {
                        delay,
                        deepcopy,
                    }
                }
            }
            if (name === 'react-dom') return {
                name,
                module: await import('react-dom'),
            }
            if (name === 'o2-design') return {
                name,
                module: await import('o2-design')
            }
            if (name === 'choerodon-ui') {
                const [c7n] = await Promise.all([
                    import('choerodon-ui'),
                ])
                return {
                    name,
                    module: c7n,
                }
            }
            if (name === 'choerodon-ui/pro') {
                return {
                    name,
                    module: C7NPro,
                }
            }
            if (name === 'moment') {
                const [moment] = await Promise.all([
                    import('moment'),
                ])
                return {
                    name,
                    module: moment,
                }
            }
            if (name === 'mobx') {
                const [moment] = await Promise.all([
                    import('mobx'),
                ])
                return {
                    name,
                    module: moment,
                }
            }
            if (name === 'mobx-react') {
                const [moment] = await Promise.all([
                    import('mobx-react'),
                ])
                return {
                    name,
                    module: moment,
                }
            }
            if (name === './http') {
                const [TokenService] = await Promise.all([
                    import('../../../o2-design/http'),
                ])
                return {
                    name,
                    module: TokenService,
                }
            }
            if (name === './token') {
                const [TokenService] = await Promise.all([
                    import('../../../request/token'),
                ])
                return {
                    name,
                    module: TokenService,
                }
            }
            if (name === 'cms-data.json') {
                const [data] = await Promise.all([
                    import('../../../doc/5.其他组件/cms-data.json'),
                ])
                return {
                    name,
                    module: data,
                }
            }
            if (name === 'cms-data-doc.js') {
                const [data] = await Promise.all([
                    import('../../../doc/5.其他组件/cms-data-doc.js' as any),
                ])
                return {
                    name,
                    module: data,
                }
            }
            if (name === 'cms-local-data.json') {
                const [data] = await Promise.all([
                    import('../../../doc/5.其他组件/cms-local-data.json'),
                ])
                return {
                    name,
                    module: data,
                }
            }
            if (name === 'o2-flow.data.json') {
                const [data] = await Promise.all([
                    import('../../../doc/5.其他组件/o2-flow.data.json'),
                ])
                return {
                    name,
                    module: data,
                }
            }
            /*if (name === 'icon-search') return {
                name,
                module: await import('../../components/IconSearch')
            }*/

            /*if (name === 'data.json') return {
                name,
                module: (await import('../../../../doc/data/data.json')).default,
            }
            if (name === 'data-1.json') return {
                name,
                module: (await import('../../../../doc/data/data-1.json')).default,
            }
            if (name === 'data-2.json') return {
                name,
                module: (await import('../../../../doc/data/data-2.json')).default,
            }
            if (name === 'tree.data.json') return {
                name,
                module: (await import('../../../../doc/data/tree.data.json')).default,
            }
            if (name === 'address.json') return {
                name,
                module: (await import('../../../../doc/data/address.json')).default,
            }*/

            const msg = `没有安装依赖：${name}`
            notification.warn({message: '警告', description: msg})
            throw new Error(msg)
        }))).reduce((prev, {name, module}) => {
            prev[name] = module
            return prev
        }, {} as Record<string, any>)
        return (name: string) => {
            return dependencies[name]
        }
    } catch (e) {
        console.error(e)
        /*setTimeout(() => loading.fail(), 300)*/
    } finally {
        /*setTimeout(() => loading.done(), 300)*/
    }
}

async function processScriptString(scriptString: string) {

    scriptString = scriptString.replace(/<>/g, '<React.Fragment>').replace(/<\/>/g, '</React.Fragment>')

    const output = Babel.transform(scriptString, {
            plugins: [
                JSXModel,
                'transform-decorators-legacy',
            ],
            presets: [
                'latest', 'stage-0', 'react',
            ],
        }
    ).code;
    // console.log(output)
    const require = await buildDependency(output)

    const fn = new Function('module,exports,require,React', output)
    const module = {exports: {} as any,}
    try {
        fn.call(module, module, module.exports, require, React)
    } catch (e) {
        console.error(e)
        return null
    }
    return module.exports.default
}
