import {designComponent, reactive, onMounted, PropType} from "o2-design";
import {MdcJsxDoc} from "./process/processJsxDoc";
import React from "react";
import {Babel} from "./mdc.utils";
import {JsxDocUtils} from "./jsxdoc/JsxDocComponents";

export const MdcJsxDocComponent = designComponent({
    props: {
        mdc: {type: Object as PropType<MdcJsxDoc>, required: true},
    },
    setup({props}) {

        const state = reactive({
            renderContent: null as any,
        })

        onMounted(async () => {
            const output = Babel.transform(props.mdc.jsxDoc, {presets: ['latest', 'stage-0', 'react',],}).code;

            const provideComponentKeys = Object.keys(JsxDocUtils.registration)
            const provideComponents = Object.values(JsxDocUtils.registration)

            const fn = new Function(`module,exports,React,${provideComponentKeys.join(',')}`, output)
            const module = {exports: {} as any,}
            try {
                fn.call(module, module, module.exports, React, ...provideComponents)
            } catch (e) {
                console.error(e)
                return null
            }
            state.renderContent = () => Object.values(module.exports)
        })

        return () => !!state.renderContent && state.renderContent()
    },
})
