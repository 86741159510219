export default [
    {
        "seq": 0.8,
        "name": "O2Design",
        "dirName": "0_8.O2Design",
        "children": [
            {
                "seq": 1,
                "name": "前言",
                "ext": "md",
                "fileName": "1.前言",
                "path": "0_8.O2Design/1.前言"
            },
            {
                "seq": 2,
                "name": "组合式开发",
                "ext": "md",
                "fileName": "2.组合式开发",
                "path": "0_8.O2Design/2.组合式开发"
            },
            {
                "seq": 3,
                "name": "响应式开发",
                "ext": "md",
                "fileName": "3.响应式开发",
                "path": "0_8.O2Design/3.响应式开发"
            },
            {
                "seq": 4,
                "name": "生命周期",
                "ext": "md",
                "fileName": "4.生命周期",
                "path": "0_8.O2Design/4.生命周期"
            },
            {
                "seq": 5,
                "name": "组件上下文",
                "ext": "md",
                "fileName": "5.组件上下文",
                "path": "0_8.O2Design/5.组件上下文"
            },
            {
                "seq": 6,
                "name": "DsTable",
                "ext": "md",
                "fileName": "6.DsTable",
                "path": "0_8.O2Design/6.DsTable"
            },
            {
                "seq": 7,
                "name": "DsForm",
                "ext": "md",
                "fileName": "7.DsForm",
                "path": "0_8.O2Design/7.DsForm"
            },
            {
                "seq": 8,
                "name": "属性说明及开发规范",
                "ext": "md",
                "fileName": "8.属性说明及开发规范",
                "path": "0_8.O2Design/8.属性说明及开发规范"
            }
        ]
    },
    {
        "seq": 1,
        "name": "表单组件",
        "dirName": "1.表单组件",
        "children": [
            {
                "seq": 1,
                "name": "O2Button 按钮",
                "ext": "md",
                "fileName": "1.O2Button 按钮",
                "path": "1.表单组件/1.O2Button 按钮"
            },
            {
                "seq": 2,
                "name": "O2Input 输入框",
                "ext": "md",
                "fileName": "2.O2Input 输入框",
                "path": "1.表单组件/2.O2Input 输入框"
            },
            {
                "seq": 3,
                "name": "O2InputNumber数字输入",
                "ext": "md",
                "fileName": "3.O2InputNumber数字输入",
                "path": "1.表单组件/3.O2InputNumber数字输入"
            },
            {
                "seq": 3.1,
                "name": "O2Currency 货币输入",
                "ext": "md",
                "fileName": "3_1.O2Currency 货币输入",
                "path": "1.表单组件/3_1.O2Currency 货币输入"
            },
            {
                "seq": 4,
                "name": "O2Select 下拉选择",
                "ext": "md",
                "fileName": "4.O2Select 下拉选择",
                "path": "1.表单组件/4.O2Select 下拉选择"
            },
            {
                "seq": 5,
                "name": "O2Lov 下拉值集",
                "ext": "md",
                "fileName": "5.O2Lov 下拉值集",
                "path": "1.表单组件/5.O2Lov 下拉值集"
            },
            {
                "seq": 6,
                "name": "O2Object 对象选择",
                "ext": "md",
                "fileName": "6.O2Object 对象选择",
                "path": "1.表单组件/6.O2Object 对象选择"
            },
            {
                "seq": 7,
                "name": "O2LovView 值集视图",
                "ext": "md",
                "fileName": "7.O2LovView 值集视图",
                "path": "1.表单组件/7.O2LovView 值集视图"
            },
            {
                "seq": 8,
                "name": "O2Switch 开关按钮",
                "ext": "md",
                "fileName": "8.O2Switch 开关按钮",
                "path": "1.表单组件/8.O2Switch 开关按钮"
            },
            {
                "seq": 9,
                "name": "O2DatePicker 日期选择",
                "ext": "md",
                "fileName": "9.O2DatePicker 日期选择",
                "path": "1.表单组件/9.O2DatePicker 日期选择"
            },
            {
                "seq": 10,
                "name": "O2Radio 单选框",
                "ext": "md",
                "fileName": "10.O2Radio 单选框",
                "path": "1.表单组件/10.O2Radio 单选框"
            },
            {
                "seq": 11,
                "name": "O2Checkbox 复选框",
                "ext": "md",
                "fileName": "11.O2Checkbox 复选框",
                "path": "1.表单组件/11.O2Checkbox 复选框"
            },
            {
                "seq": 12,
                "name": "O2Address 地址",
                "ext": "md",
                "fileName": "12.O2Address 地址",
                "path": "1.表单组件/12.O2Address 地址"
            },
            {
                "seq": 13,
                "name": "O2Textarea 文本域",
                "ext": "md",
                "fileName": "13.O2Textarea 文本域",
                "path": "1.表单组件/13.O2Textarea 文本域"
            },
            {
                "seq": 14,
                "name": "O2TextareaInput 弹框文本域",
                "ext": "md",
                "fileName": "14.O2TextareaInput 弹框文本域",
                "path": "1.表单组件/14.O2TextareaInput 弹框文本域"
            },
            {
                "seq": 15,
                "name": "O2IntlField 多语言",
                "ext": "md",
                "fileName": "15.O2IntlField 多语言",
                "path": "1.表单组件/15.O2IntlField 多语言"
            },
            {
                "seq": 16,
                "name": "O2JsonEditor Json编辑器",
                "ext": "md",
                "fileName": "16.O2JsonEditor Json编辑器",
                "path": "1.表单组件/16.O2JsonEditor Json编辑器"
            },
            {
                "seq": 17,
                "name": "O2ColorPicker 颜色选择",
                "ext": "md",
                "fileName": "17.O2ColorPicker 颜色选择",
                "path": "1.表单组件/17.O2ColorPicker 颜色选择"
            },
            {
                "seq": 18,
                "name": "O2IconPicker 图标选择",
                "ext": "md",
                "fileName": "18.O2IconPicker 图标选择",
                "path": "1.表单组件/18.O2IconPicker 图标选择"
            },
            {
                "seq": 19,
                "name": "O2RichEditor 富文本编辑",
                "ext": "md",
                "fileName": "19.O2RichEditor 富文本编辑",
                "path": "1.表单组件/19.O2RichEditor 富文本编辑"
            },
            {
                "seq": 20,
                "name": "O2IntlRichEditor 多语言富文本编辑",
                "ext": "md",
                "fileName": "20.O2IntlRichEditor 多语言富文本编辑",
                "path": "1.表单组件/20.O2IntlRichEditor 多语言富文本编辑"
            },
            {
                "seq": 20,
                "name": "O2Upload 文件上传",
                "ext": "md",
                "fileName": "20.O2Upload 文件上传",
                "path": "1.表单组件/20.O2Upload 文件上传"
            }
        ]
    },
    {
        "seq": 2,
        "name": "表单开发",
        "dirName": "2.表单开发",
        "children": [
            {
                "seq": 1,
                "name": "Basic 基础表单",
                "ext": "md",
                "fileName": "1.Basic 基础表单",
                "path": "2.表单开发/1.Basic 基础表单"
            },
            {
                "seq": 2,
                "name": "Layout 表单分列布局",
                "ext": "md",
                "fileName": "2.Layout 表单分列布局",
                "path": "2.表单开发/2.Layout 表单分列布局"
            },
            {
                "seq": 3,
                "name": "Validator 表单校验",
                "ext": "md",
                "fileName": "3.Validator 表单校验",
                "path": "2.表单开发/3.Validator 表单校验"
            },
            {
                "seq": 4,
                "name": "Control 表单控制",
                "ext": "md",
                "fileName": "4.Control 表单控制",
                "path": "2.表单开发/4.Control 表单控制"
            },
            {
                "seq": 5,
                "name": "Components 所有组件",
                "ext": "md",
                "fileName": "5.Components 所有组件",
                "path": "2.表单开发/5.Components 所有组件"
            },
            {
                "seq": 6,
                "name": "FormOption 自动表单",
                "ext": "md",
                "fileName": "6.FormOption 自动表单",
                "path": "2.表单开发/6.FormOption 自动表单"
            },
            {
                "seq": 7,
                "name": "Recommend 最佳实践",
                "ext": "md",
                "fileName": "7.Recommend 最佳实践",
                "path": "2.表单开发/7.Recommend 最佳实践"
            }
        ]
    },
    {
        "seq": 3,
        "name": "数据表格",
        "dirName": "3.数据表格",
        "children": [
            {
                "seq": 1,
                "name": "Basic 增删改查",
                "ext": "md",
                "fileName": "1.Basic 增删改查",
                "path": "3.数据表格/1.Basic 增删改查"
            },
            {
                "seq": 1.5,
                "name": "Config 配置",
                "ext": "md",
                "fileName": "1_5.Config 配置",
                "path": "3.数据表格/1_5.Config 配置"
            },
            {
                "seq": 1.6,
                "name": "Button 按钮",
                "ext": "md",
                "fileName": "1_6.Button 按钮",
                "path": "3.数据表格/1_6.Button 按钮"
            },
            {
                "seq": 2,
                "name": "Query 表单查询",
                "ext": "md",
                "fileName": "2.Query 表单查询",
                "path": "3.数据表格/2.Query 表单查询"
            },
            {
                "seq": 3,
                "name": "Form 表单编辑",
                "ext": "md",
                "fileName": "3.Form 表单编辑",
                "path": "3.数据表格/3.Form 表单编辑"
            },
            {
                "seq": 4,
                "name": "Slots 列插槽",
                "ext": "md",
                "fileName": "4.Slots 列插槽",
                "path": "3.数据表格/4.Slots 列插槽"
            },
            {
                "seq": 6,
                "name": "Check 多选",
                "ext": "md",
                "fileName": "6.Check 多选",
                "path": "3.数据表格/6.Check 多选"
            },
            {
                "seq": 7,
                "name": "Hooks 钩子函数",
                "ext": "md",
                "fileName": "7.Hooks 钩子函数",
                "path": "3.数据表格/7.Hooks 钩子函数"
            },
            {
                "seq": 8,
                "name": "Fill 高度自适应",
                "ext": "md",
                "fileName": "8.Fill 高度自适应",
                "path": "3.数据表格/8.Fill 高度自适应"
            },
            {
                "seq": 9,
                "name": "Groups 分组表头",
                "ext": "md",
                "fileName": "9.Groups 分组表头",
                "path": "3.数据表格/9.Groups 分组表头"
            },
            {
                "seq": 10,
                "name": "Methods 可用方法",
                "ext": "md",
                "fileName": "10.Methods 可用方法",
                "path": "3.数据表格/10.Methods 可用方法"
            },
            {
                "seq": 11,
                "name": "All 所有列",
                "ext": "md",
                "fileName": "11.All 所有列",
                "path": "3.数据表格/11.All 所有列"
            },
            {
                "seq": 12,
                "name": "大数据表格",
                "ext": "md",
                "fileName": "12.大数据表格",
                "path": "3.数据表格/12.大数据表格"
            },
            {
                "seq": 13,
                "name": "Remove 临时移除行",
                "ext": "md",
                "fileName": "13.Remove 临时移除行",
                "path": "3.数据表格/13.Remove 临时移除行"
            },
            {
                "seq": 14,
                "name": "Drag 行拖拽",
                "ext": "md",
                "fileName": "14.Drag 行拖拽",
                "path": "3.数据表格/14.Drag 行拖拽"
            },
            {
                "seq": 15,
                "name": "Multi 多行编辑",
                "ext": "md",
                "fileName": "15.Multi 多行编辑",
                "path": "3.数据表格/15.Multi 多行编辑"
            },
            {
                "seq": 16,
                "name": "C7N 表格配置",
                "ext": "md",
                "fileName": "16.C7N 表格配置",
                "path": "3.数据表格/16.C7N 表格配置"
            }
        ]
    },
    {
        "seq": 4,
        "name": "数据表格列",
        "dirName": "4.数据表格列",
        "children": [
            {
                "seq": 1,
                "name": "Column 基础列",
                "ext": "md",
                "fileName": "1.Column 基础列",
                "path": "4.数据表格列/1.Column 基础列"
            },
            {
                "seq": 2,
                "name": "Input 输入框列",
                "ext": "md",
                "fileName": "2.Input 输入框列",
                "path": "4.数据表格列/2.Input 输入框列"
            },
            {
                "seq": 3,
                "name": "InputNumber 数字输入列",
                "ext": "md",
                "fileName": "3.InputNumber 数字输入列",
                "path": "4.数据表格列/3.InputNumber 数字输入列"
            },
            {
                "seq": 4,
                "name": "Select 下拉列",
                "ext": "md",
                "fileName": "4.Select 下拉列",
                "path": "4.数据表格列/4.Select 下拉列"
            },
            {
                "seq": 5,
                "name": "Lov 下拉值集列",
                "ext": "md",
                "fileName": "5.Lov 下拉值集列",
                "path": "4.数据表格列/5.Lov 下拉值集列"
            },
            {
                "seq": 6,
                "name": "LovView 值集视图列",
                "ext": "md",
                "fileName": "6.LovView 值集视图列",
                "path": "4.数据表格列/6.LovView 值集视图列"
            },
            {
                "seq": 7,
                "name": "Switch 开关列",
                "ext": "md",
                "fileName": "7.Switch 开关列",
                "path": "4.数据表格列/7.Switch 开关列"
            },
            {
                "seq": 8,
                "name": "DatePicker 日期列",
                "ext": "md",
                "fileName": "8.DatePicker 日期列",
                "path": "4.数据表格列/8.DatePicker 日期列"
            },
            {
                "seq": 9,
                "name": "Checkbox 复选框列",
                "ext": "md",
                "fileName": "9.Checkbox 复选框列",
                "path": "4.数据表格列/9.Checkbox 复选框列"
            },
            {
                "seq": 10,
                "name": "Address 地址列",
                "ext": "md",
                "fileName": "10.Address 地址列",
                "path": "4.数据表格列/10.Address 地址列"
            },
            {
                "seq": 11,
                "name": "Textarea 文本域列",
                "ext": "md",
                "fileName": "11.Textarea 文本域列",
                "path": "4.数据表格列/11.Textarea 文本域列"
            },
            {
                "seq": 12,
                "name": "JsonEditor Json编辑器列",
                "ext": "md",
                "fileName": "12.JsonEditor Json编辑器列",
                "path": "4.数据表格列/12.JsonEditor Json编辑器列"
            },
            {
                "seq": 13,
                "name": "IntlField 多语言列",
                "ext": "md",
                "fileName": "13.IntlField 多语言列",
                "path": "4.数据表格列/13.IntlField 多语言列"
            },
            {
                "seq": 14,
                "name": "IconPicker 图标选择列",
                "ext": "md",
                "fileName": "14.IconPicker 图标选择列",
                "path": "4.数据表格列/14.IconPicker 图标选择列"
            },
            {
                "seq": 15,
                "name": "AutoComplete 自动完成",
                "ext": "md",
                "fileName": "15.AutoComplete 自动完成",
                "path": "4.数据表格列/15.AutoComplete 自动完成"
            }
        ]
    },
    {
        "seq": 4.1,
        "name": "表格表单公用功能",
        "dirName": "4_1.表格表单公用功能",
        "children": [
            {
                "seq": 1,
                "name": "deepField 链式字段",
                "ext": "md",
                "fileName": "1.deepField 链式字段",
                "path": "4_1.表格表单公用功能/1.deepField 链式字段"
            },
            {
                "seq": 2,
                "name": "cascadeFields 字段联动",
                "ext": "md",
                "fileName": "2.cascadeFields 字段联动",
                "path": "4_1.表格表单公用功能/2.cascadeFields 字段联动"
            }
        ]
    },
    {
        "seq": 5,
        "name": "其他组件",
        "dirName": "5.其他组件",
        "children": [
            {
                "seq": 1,
                "name": "FIlter 查询表单",
                "ext": "md",
                "fileName": "1.FIlter 查询表单",
                "path": "5.其他组件/1.FIlter 查询表单"
            },
            {
                "seq": 3,
                "name": "O2Flow 流程编辑组件",
                "ext": "md",
                "fileName": "3.O2Flow 流程编辑组件",
                "path": "5.其他组件/3.O2Flow 流程编辑组件"
            },
            {
                "seq": 4,
                "name": "O2CMS Designer_blank",
                "ext": "md",
                "fileName": "4.O2CMS Designer_blank",
                "path": "5.其他组件/4.O2CMS Designer_blank"
            },
            {
                "seq": 5,
                "name": "O2CMS 操作手册",
                "ext": "md",
                "fileName": "5.O2CMS 操作手册",
                "path": "5.其他组件/5.O2CMS 操作手册"
            },
            {
                "seq": 6,
                "name": "dnd 拖拽组件",
                "ext": "md",
                "fileName": "6.dnd 拖拽组件",
                "path": "5.其他组件/6.dnd 拖拽组件"
            },
            {
                "seq": 7,
                "name": "工具函数",
                "ext": "md",
                "fileName": "7.工具函数",
                "path": "5.其他组件/7.工具函数"
            },
            {
                "seq": null,
                "name": "js",
                "fileName": "cms-data-doc.js",
                "path": "5.其他组件/cms-data-doc.js"
            }
        ]
    },
    {
        "seq": 6,
        "name": "组件服务",
        "dirName": "6.组件服务",
        "children": [
            {
                "seq": 0,
                "name": "全局配置",
                "ext": "md",
                "fileName": "0.全局配置",
                "path": "6.组件服务/0.全局配置"
            },
            {
                "seq": 1,
                "name": "$$lov",
                "ext": "md",
                "fileName": "1.$$lov",
                "path": "6.组件服务/1.$$lov"
            },
            {
                "seq": 2,
                "name": "useO2CaptchaModal",
                "ext": "md",
                "fileName": "2.useO2CaptchaModal",
                "path": "6.组件服务/2.useO2CaptchaModal"
            },
            {
                "seq": 3,
                "name": "O2ButtonCollapse",
                "ext": "md",
                "fileName": "3.O2ButtonCollapse",
                "path": "6.组件服务/3.O2ButtonCollapse"
            }
        ]
    },
    {
        "seq": 7,
        "name": "组件测试",
        "dirName": "7.组件测试",
        "children": [
            {
                "seq": 1,
                "name": "测试入门",
                "ext": "md",
                "fileName": "1.测试入门",
                "path": "7.组件测试/1.测试入门"
            }
        ]
    },
    {
        "seq": 99,
        "name": "文档工程",
        "dirName": "99.文档工程",
        "children": [
            {
                "seq": 0,
                "name": "简介",
                "ext": "md",
                "fileName": "0.简介",
                "path": "99.文档工程/0.简介"
            },
            {
                "seq": 0.1,
                "name": "目录结构",
                "ext": "md",
                "fileName": "0_1.目录结构",
                "path": "99.文档工程/0_1.目录结构"
            },
            {
                "seq": 1,
                "name": "Markdown语法",
                "ext": "tsx",
                "fileName": "1.Markdown语法",
                "path": "99.文档工程/1.Markdown语法"
            },
            {
                "seq": 2,
                "name": "菜单扫描原理",
                "ext": "md",
                "fileName": "2.菜单扫描原理",
                "path": "99.文档工程/2.菜单扫描原理"
            },
            {
                "seq": 3,
                "name": "Markdown解析",
                "ext": "md",
                "fileName": "3.Markdown解析",
                "path": "99.文档工程/3.Markdown解析"
            },
            {
                "seq": 4,
                "name": "登陆token",
                "ext": "md",
                "fileName": "4.登陆token",
                "path": "99.文档工程/4.登陆token"
            },
            {
                "seq": 5,
                "name": "更新日志",
                "ext": "tsx",
                "fileName": "5.更新日志",
                "path": "99.文档工程/5.更新日志"
            }
        ]
    },
    {
        "seq": 100,
        "name": "常见问题",
        "dirName": "100.常见问题",
        "children": [
            {
                "seq": 1,
                "name": "响应式数据的陷阱",
                "ext": "md",
                "fileName": "1.响应式数据的陷阱",
                "path": "100.常见问题/1.响应式数据的陷阱"
            },
            {
                "seq": 2,
                "name": "网络请求与异步任务处理",
                "ext": "md",
                "fileName": "2.网络请求与异步任务处理",
                "path": "100.常见问题/2.网络请求与异步任务处理"
            },
            {
                "seq": 3,
                "name": "单列如何实现编辑多值",
                "ext": "md",
                "fileName": "3.单列如何实现编辑多值",
                "path": "100.常见问题/3.单列如何实现编辑多值"
            },
            {
                "seq": 4,
                "name": "获取查询表单的筛选参数",
                "ext": "md",
                "fileName": "4.获取查询表单的筛选参数",
                "path": "100.常见问题/4.获取查询表单的筛选参数"
            },
            {
                "seq": 5,
                "name": "页面之间的状态共享",
                "ext": "md",
                "fileName": "5.页面之间的状态共享",
                "path": "100.常见问题/5.页面之间的状态共享"
            },
            {
                "seq": 6,
                "name": "隐藏列但是有查询功能",
                "ext": "md",
                "fileName": "6.隐藏列但是有查询功能",
                "path": "100.常见问题/6.隐藏列但是有查询功能"
            },
            {
                "seq": 7,
                "name": "O2Table响应卡顿",
                "ext": "md",
                "fileName": "7.O2Table响应卡顿",
                "path": "100.常见问题/7.O2Table响应卡顿"
            },
            {
                "seq": 8,
                "name": "O2Table列动态设置required",
                "ext": "md",
                "fileName": "8.O2Table列动态设置required",
                "path": "100.常见问题/8.O2Table列动态设置required"
            },
            {
                "seq": 9,
                "name": "O2Table动态增删Column问题",
                "ext": "md",
                "fileName": "9.O2Table动态增删Column问题",
                "path": "100.常见问题/9.O2Table动态增删Column问题"
            },
            {
                "seq": 10,
                "name": "Hooks相关",
                "ext": "md",
                "fileName": "10.Hooks相关",
                "path": "100.常见问题/10.Hooks相关"
            }
        ]
    }
]