/**
 * 环境变量配置，只有本地调试的时候可以切换环境，比如
 * @author  韦胜健
 * @date    2021/11/4 15:23
 */

declare const ENV: {
    publicPath: string,
    loginURL: string,
    baseURL: string,
}
declare const BUILD_TIME: number;

export const env = ENV
console.log(new Date(BUILD_TIME), {env})
export const pathJoin = (path1?: string, path2?: string) => {
    return ((path1 || '/') + (path2 || '/')).replace('//', '/')
}
export const buildAssetsPath = (path: string) => pathJoin(env.publicPath, path)
