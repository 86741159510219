import './markdown.less'
import {designComponent, nextTick, useRefs, watch} from "o2-design";
import {renderMarkdown} from "./markdown-it";
import React from 'react';

export const O2Markdown = designComponent({
    name: 'o2-markdown',
    props: {
        md: {type: String, required: true},
    },
    setup({props}) {
        const {refs, onRef} = useRefs({
            el: HTMLDivElement,
        })
        watch(() => props.md, async (val: string | undefined) => {
            await nextTick()
            renderMarkdown(val, refs.el!)
        }, {immediate: true})
        return {
            render: () => {
                return (
                    <div className="pl-markdown" ref={onRef.el}/>
                )
            }
        }
    },
})
