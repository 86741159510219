import axios from 'axios';
import {configure, message} from 'choerodon-ui';
import {env} from "../env/env";
import {TokenService} from "../request/token";
import {QueryBarMore} from "./QueryBarMore";
import React from 'react'
import './c7n.locale'

const API_HOST = env.baseURL
const HZERO_PLATFORM = 'hpfm'

const jsonMimeType = 'application/json';

const getCurrentOrganizationId = () => 2

export const withTokenAxios = axios.create({
    headers: {
        // Authorization: `bearer ${getAccessToken()}`,
        'Content-Type': jsonMimeType,
        Accept: jsonMimeType,
        'X-Requested-With': 'XMLHttpRequest',
        // baseURL: API_HOST,
    },
});

// Add a request interceptor
withTokenAxios.interceptors.request.use(
    async (config) => {
        let {url = ''} = config;
        if (url.indexOf('://') === -1) {
            url = `${API_HOST}${url}`;
        }
        // Do something before request is sent
        return {
            ...config,
            url,
            headers: {
                ...config.headers,
                Authorization: await TokenService.getToken(),
            },
        };
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

withTokenAxios.interceptors.response.use((response) => {
    const {status, data} = response;
    if (status === 204) {
        return response;
    }
    if (data && data.failed) {
        // notification.error({
        //   message: data.message,
        // });
        throw data;
    } else {
        return data;
    }
});

// axios.defaults.headers.common.Authorization = `bearer ${getAccessToken()}`;
message.config({
    placement: 'bottomRight',
    bottom: 48,
    duration: 2,
});
configure({
    prefixCls: 'c7n',
    proPrefixCls: 'c7n-pro',
    lookupUrl: (code) => `${API_HOST}${HZERO_PLATFORM}/v1/${getCurrentOrganizationId()}/lovs/data?lovCode=${code}`,
    ripple: false,
    lovDefineAxiosConfig: (code) => ({
        url: `${API_HOST}${HZERO_PLATFORM}/v1/lov-view/info?viewCode=${code}`,
        method: 'GET',
        transformResponse: [
            (data) => {
                // 对 data 进行任意转换处理
                let originData = {};

                try {
                    originData = JSON.parse(data);
                } catch (e) {
                    console.error(e, data);
                    return data;
                }

                const {
                    viewCode = code,
                    valueField = 'value',
                    displayField = 'name',
                    pageSize = 5,
                    queryFields = [],
                    tableFields = [],
                    // queryUrl,
                } = originData;
                let {title} = originData;
                if (originData.failed) {
                    title = `值集视图未定义: "${code}", 请维护值集视图!`;
                } else if (!originData.lovCode) {
                    title = `lov ${code} loading...`;
                }
                const lovItems = [];
                let tableWidth = 0;
                queryFields.forEach((queryItem) => {
                    const lovItem = {
                        lovId: viewCode,
                        lovItemId: `query_${queryItem.field}`,
                        gridFieldName: queryItem.field,
                        gridField: 'N',
                        display: queryItem.label,
                        autocompleteField: 'Y',
                        conditionField: 'Y',
                        isAutocomplete: 'N',
                        conditionFieldWidth: null,
                        conditionFieldLabelWidth: null,
                        conditionFieldType: null,
                        conditionFieldName: null,
                        conditionFieldTextfield: null,
                        conditionFieldNewline: 'N',
                        conditionFieldSelectUrl: null,
                        conditionFieldSelectVf: null,
                        conditionFieldSelectTf: null,
                        conditionFieldSelectCode: null,
                        conditionFieldLovCode: null,
                        conditionFieldSequence: 1,
                        gridFieldSequence: 1,
                    };
                    lovItems.push(lovItem);
                });
                tableFields.forEach((tableItem) => {
                    const lovItem = {
                        lovId: viewCode,
                        lovItemId: `table_${tableItem.dataIndex}`,
                        gridFieldName: tableItem.dataIndex,
                        gridFieldWidth: tableItem.width,
                        gridFieldAlign: 'left',
                        autocompleteField: 'Y',
                        conditionField: 'Y',
                        isAutocomplete: 'N',
                        gridField: 'Y',
                        display: tableItem.title,
                        conditionFieldWidth: null,
                        conditionFieldLabelWidth: null,
                        conditionFieldType: null,
                        conditionFieldName: null,
                        conditionFieldTextfield: null,
                        conditionFieldNewline: 'N',
                        conditionFieldSelectUrl: null,
                        conditionFieldSelectVf: null,
                        conditionFieldSelectTf: null,
                        conditionFieldSelectCode: null,
                        conditionFieldLovCode: null,
                        conditionFieldSequence: 1,
                        gridFieldSequence: 1,
                    };
                    lovItems.push(lovItem);
                    tableWidth += tableItem.width;
                });

                const convertedData = {
                    originData: {
                        lovCode: code,
                        ...originData,
                    },
                    code: viewCode,
                    title: title || code,
                    description: title || code,
                    lovId: viewCode,
                    placeholder: title || code,
                    sqlId: viewCode,
                    customSql: null,
                    queryColumns: queryFields && queryFields.length ? 1 : 0,
                    customUrl: null,
                    textField: displayField,
                    valueField,
                    delayLoad: 'N',
                    needQueryParam: 'N',
                    editableFlag: 'Y',
                    canPopup: 'Y',
                    lovPageSize: pageSize,
                    treeFlag: 'N',
                    idField: null,
                    parentIdField: null,
                    lovItems,
                    width: tableWidth ? tableWidth + 120 : 520,
                };
                return convertedData;
            },
        ],
    }),
    lovTableProps: {
        selectionMode: 'rowbox',
        onRow: ({dataSet, record}) => {
            return {
                onClick: () => {
                    dataSet
                        .filter((item) => item.isSelected === true)
                        .forEach((recordItem) => {
                            Object.assign(recordItem, {isSelected: false});
                        });
                    Object.assign(record, {isSelected: true});
                },
            };
        },
    },
    lovQueryAxiosConfig: (code, lovConfig = {}) => {
        const {queryUrl, lovCode} = lovConfig.originData || {};
        let url = `${API_HOST}${HZERO_PLATFORM}/v1/lovs/data?lovCode=${lovCode}`;
        if (queryUrl) {
            url = `${API_HOST}${queryUrl}?lovCode=${lovCode}`; // lovCode=${lovCode}
            const organizationRe = /\{organizationId\}|\{tenantId\}/g;
            if (organizationRe.test(url)) {
                const tId = getCurrentOrganizationId();
                url = url.replace(organizationRe, tId);
            }
            // url = `${url}${url.indexOf('?') ? '&' : '?'}lovCode=${lovCode}`;
        }
        return {
            url,
            method: 'GET',
        };
    },
    lookupAxiosMethod: 'GET',
    dataKey: 'content',
    totalKey: 'totalElements',
    tableRowHeight: 40,
    axios: withTokenAxios,
    queryBar: props => <QueryBarMore {...props} />,
    generatePageQuery: ({page, pageSize, sortName, sortOrder}) => ({
        page: page === undefined ? page : page - 1,
        size: pageSize,
        sort: sortName && (sortOrder ? `${sortName}, ${sortOrder}` : sortName),
    }),
    status: {
        add: 'create',
        update: 'update',
        delete: 'delete',
    },
    statusKey: '_status',
    tlsKey: '_tls',
});

export {withTokenAxios as axios};
