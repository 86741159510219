import React, {useState} from "react";
import {Form, Button} from 'choerodon-ui/pro';
import {Row, Col} from 'choerodon-ui';
import {Intl as intl} from 'o2-design'

export var FORM_COL_3_4_LAYOUT = {
    span: 18
};
export var FORM_COL_4_LAYOUT = {
    span: 6
};
export var SEARCH_FORM_ROW_LAYOUT = {
    gutter: 12
};

export const QueryBarMore = ({queryFields, buttons, queryFieldsLimit = 3, dataSet, queryDataSet}) => {
    const [hidden, setHidden] = useState(true);
    const handleToggle = () => {
        setHidden(!hidden);
    };
    const query = async () => {
        if (await dataSet.validate(false, false)) {
            await dataSet.query();
        }
    };
    return (
        <div>
            {queryDataSet && (
                <Row {...SEARCH_FORM_ROW_LAYOUT}>
                    <Col {...FORM_COL_3_4_LAYOUT}>
                        <Form
                            columns={queryFieldsLimit}
                            dataSet={queryDataSet}
                            labelWidth={[175, 175, 175]}
                            onKeyDown={e => {
                                if (e.keyCode === 13) return query();
                            }}
                        >
                            {hidden ? queryFields.slice(0, queryFieldsLimit) : queryFields}
                        </Form>
                    </Col>
                    <Col {...FORM_COL_4_LAYOUT} className="search-btn-more" style={{paddingRight: 0}}>
                        <div style={{marginTop: 10}}>
                            {queryFields.length > queryFieldsLimit && (
                                <Button onClick={handleToggle}>
                                    {hidden
                                        ? intl.get('hzero.common.button.viewMore').d('更多查询')
                                        : intl.get('hzero.common.button.collected').d('收起查询')}
                                </Button>
                            )}
                            <Button
                                onClick={() => {
                                    queryDataSet.current.reset();
                                    dataSet.fireEvent('queryBarReset', {
                                        dataSet,
                                        queryFields,
                                    });
                                }}
                            >
                                {intl.get('hzero.common.button.reset').d('重  置')}
                            </Button>
                            <Button dataSet={null} color="primary" onClick={query}>
                                {intl.get('hzero.common.button.search').d('查  询')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            )}
            {buttons && <div style={{marginBottom: 4}}>{buttons}</div>}
        </div>
    );
};
