import React from "react";

import MenuData from '../data.menus'
import {nav} from "./nav";
import {classnames, designPage, reactive} from "o2-design";
import {Icon} from "choerodon-ui";

interface Menu {
    seq: number,
    name: string,
    ext: string,
    fileName: string,
    path: string,
}

interface MenuGroup {
    seq: number,
    name: string,
    dirName: string,
    children: Menu[],
}

const externalMenu: MenuGroup[] = [
    /*{
        "seq": 999,
        "name": "其他",
        "dirName": "其他",
        "children": [
            {
                "seq": 1,
                "name": "404",
                "ext": "md",
                "fileName": "1.简介",
                "path": "随机路径"
            }
        ]
    }*/

]

const groups: MenuGroup[] = [...MenuData as any, ...externalMenu]

export const AppMenu = designPage(() => {

    const state = reactive({
        openMap: {} as Record<string, boolean | undefined>
    })

    return () => (
        <div className="app-menu">
            <div className="app-menu-title">
                O2Design
            </div>
            {groups.map((folder, folderIndex) => {
                return <React.Fragment key={folderIndex}>
                    <div className="app-menu-folder" onClick={() => {state.openMap = {...state.openMap, [folder.name]: !state.openMap[folder.name]}}}>
                        {state.openMap[folder.name] ? <Icon type="baseline-arrow_drop_down"/> : <Icon type="baseline-arrow_right"/>}
                        <span>{folder.name}</span>
                    </div>
                    {!!state.openMap[folder.name] && (
                        <div className="app-menu-children">
                            {folder.children.map((child, childIndex) => (
                                child.name !== 'js' && <div className={classnames([
                                    'app-menu-item',
                                    {'app-menu-item-active': nav.isActive(child.path)}
                                ])}
                                                            key={'child_' + childIndex} onClick={() => nav.methods.go(child.path, '', child.path.indexOf('blank') > -1)}>
                                    {child.name.replace('_blank', '')}
                                </div>
                            ))}
                        </div>
                    )}
                </React.Fragment>
            })}
        </div>
    )
})
