import Axios, {AxiosRequestConfig} from "axios";
import {notification} from "choerodon-ui";
import {TokenService} from "./token";
import {env} from "../env/env";
import {Intl} from 'o2-design'
import {deepcopy} from "plain-utils/object/deepcopy";

type iRequestConfig = AxiosRequestConfig & { query?: any, body?: any }

const axiosInstance = Axios.create({
    baseURL: env.baseURL,
})

axiosInstance.interceptors.request.use(async (config) => {
    if (!config.headers) {config.headers = {}}
    if (!config.headers.Authorization) {
        try {
            config.headers.Authorization = await TokenService.getToken()
        } catch (e) {
            notification.error({message: '', description: '获取token失败，请求无效'})
            throw e
        }
    }
    return config
})

axiosInstance.interceptors.response.use(response => {
    const {status, data} = response;

    if (status == 204) {
        return response.data
    }

    if (data && data.failed) {
        notification.error({
            message: status,
            description: Intl(`requestNotification.${status}`).d('') || data.message
        });
        throw response;
    } else {
        return response;
    }
}, (error) => {

    if (!!error && !!error.response && error.response.status == 401) {
        TokenService.getToken(true)
    }

    return error.response
})

export async function request(url: string, config: iRequestConfig) {

    if (!!config.query) {config.params = config.query}
    if (!!config.body) {config.data = config.body}

    const resp = await axiosInstance({
        url,
        ...config,
    })
    const data = deepcopy(resp?.data || {})
    return Object.assign(data, {
        _resp: resp
    })
}

