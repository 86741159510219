import {createHttp} from "o2-design";
import {request} from "../request";

const http = createHttp(async (url, config) => {
    const {query, body} = config
    return request(url, {
        ...config,
        params: query,
        data: body,
    })
})

export default http
