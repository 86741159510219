import data from './data-1.json'
import {deepcopy} from "plain-utils/object/deepcopy";
import {createCounter} from "o2-design";
import {iErrorMessage, iQueryResponse, iRequest, tRequestConfigObject} from 'o2-design/src/packages/O2Table/TableOption/request.type';

/**
 * - 新建的时候返回的对象就是新建对象
 *  -   新建完毕之后才会返回记录的id
 * - 更新的时候返回的对象也是更新后的对象
 * - 删除
 *  -   删除失败会返回失败对象
 *  -   删除成功不返回任何对象
 * @author  韦胜健
 * @date    2021/4/27 16:12
 */

/*模拟数据库中的数据*/
const DATA = deepcopy(data.slice(0, 47))

/*生成一个新的id*/
const generateId = createCounter('new_id')

export const mockData: iRequest = async (config: tRequestConfigObject) => {

    // await delay(1000)

    if (config.method === 'PATCH' || config.method === 'HEAD' || config.method === 'OPTIONS') {
        const error: iErrorMessage = {
            code: 'request.fail',
            failed: true,
            message: '不支持的请求类型：' + config.method,
            type: 'error',
        }
        return Promise.reject(error)
    }

    // console.log('request配置参数', config)

    return {
        /*查询数据*/
        'GET': () => {
            let {page: _page, size: _size, ...leftQuery} = config.query
            const page = Number(_page)
            const size = Number(_size)

            function format(val: string | number): string {
                return String(val).toLowerCase()
            }

            let data = DATA;
            ['name', 'star', 'domain', 'email', 'flag'].forEach(key => {
                if (!!leftQuery[key]) {data = data.filter(item => format((item as any)[key]).indexOf(format(leftQuery[key])) > -1)}
            })

            const elements = deepcopy(data.slice(page * size, (page + 1) * size))
            const resp: iQueryResponse = {
                content: elements,
                empty: data.length === 0,
                number: page,
                numberOfElements: elements.length,
                size: size,
                totalElements: data.length,
                totalPages: Math.ceil(data.length / size),
            }
            return resp
        },
        /*新建*/
        'POST': () => {
            const insertRow = deepcopy((config).body)
            !insertRow.id && (insertRow.id = generateId())
            DATA.unshift(deepcopy(insertRow))
            return {newRow: insertRow}
        },
        /*更新*/
        'PUT': () => {
            const updateRow = deepcopy((config).body)
            if (updateRow.id == null) {
                const error: iErrorMessage = {
                    code: 'update.fail',
                    failed: true,
                    message: '更新失败，缺少主键',
                    type: 'error',
                }
                return Promise.reject(error)
            }
            const index = DATA.findIndex(item => item.id === updateRow.id)
            if (index === -1) {
                const error: iErrorMessage = {
                    code: 'update.fail',
                    failed: true,
                    message: '更新失败，找不到对应主键的数据',
                    type: 'error',
                }
                return Promise.reject(error)
            }
            DATA[index] = deepcopy(updateRow)
            return {newRow: deepcopy(DATA[index])}
        },
        /*删除*/
        'DELETE': () => {
            const deleteRow = deepcopy((config).body)
            if (deleteRow.id == null) {
                const error: iErrorMessage = {
                    code: 'update.fail',
                    failed: true,
                    message: '更新失败，缺少主键',
                    type: 'error',
                }
                return Promise.reject(error)
            }
            const index = DATA.findIndex(item => item.id === deleteRow.id)
            if (index === -1) {
                const error: iErrorMessage = {
                    code: 'update.fail',
                    failed: true,
                    message: '更新失败，找不到对应主键的数据',
                    type: 'error',
                }
                return Promise.reject(error)
            }
            DATA.splice(index, 1)
            return null
        },
    }[config.method]()
}
