import {designPage} from "o2-design";
import React from "react";

export const AppFooter = designPage(() => {

    return () => (
        <div className="app-footer">
            <div className="f14 foot-bottom">
                <div className="foot-bottom-content">
                    <div className="foot-left">© Copyright Hand China Co.,Ltd. All Rights Reserved
                        上海汉得信息技术股份有限公司
                    </div>

                    <div className="foot-right">
                        <div>
                            <a href="http://beian.miit.gov.cn" target="_blank">沪ICP备14039535号-1</a>
                        </div>
                        <div>
                            <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011802002577"
                               target="_blank">沪公网安备 31011802002577号</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
