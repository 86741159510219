import {designPage} from "o2-design";
import React from "react";
import {nav} from "./nav";
import {AppFooter} from "./AppFooter";

export const AppContent = designPage(() => {
    return () => (
        <div className="app-content" ref={nav.render.onRef.el}>
            {nav.render.renderContent()}
            <div className="app-nav-list">
                {nav.render.renderNavList()}
            </div>
            <AppFooter/>
        </div>
    )
})