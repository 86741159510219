import {O2Table, Intl} from 'o2-design';
import {getDefaultUrlConfig} from './getDefaultUrlConfig';


export default O2Table.createTableOptionUser({
    keyField: 'id',
    rowHeight: 40,
    headRowHeight: 40,
    indexing: true,
    bordered: true,
    pageSizeOptions: ["10", "20", "50", "100"],
    loadOnStart: true,
    defaultShowRow: 10,
    hideButton: {},
    getDefaultUrlConfig,
    defaultNewRow: {},
    copyExcludeKeys: [],
    filterColumns: 3,
    operatorColumnWidth: 160,
    checkCache: false,
    injectRules: (queries, requestConfig) => {
        const data =
            requestConfig.method.toLowerCase() === 'get' ? requestConfig.query : requestConfig.body;
        queries.forEach(({operator, field, value}) => {
            if (value == null || value === '') {
                return;
            }
            switch (operator) {
                case '~':
                case '=':
                    return (data[field] = value);
                default:
                    return new Error('无法识别的筛选操作符:' + operator);
            }
        });
    },
    multipleEdit: false,
    configure: {},
});

/* eslint-enable */
