import qs from 'qs'
import {notification} from "choerodon-ui";
import {AppRoute, buildHref, nav} from "../home/nav";
import {env} from "../env/env";

// access_token=088fb02a-6a7d-45f2-88a6-4f12370ae16c&token_type=bearer&expires_in=863999&refresh_token=607a299c-5fa6-4c6e-afb1-4ae1684759b1&scope=default

export interface iToken {
    access_token: string,               // 请求的时候使用到的token
    token_type: string,                 // token的前缀
    expires_in: string,                 // token过期时间，单位是毫秒
    refresh_token: string,              // ?
    scope: string,                      // ?
    now: string,                        // 获取token的时候的事件，now+expires_in可以算出当前时间是否超过了token的有效时间
}

interface CacheRouteData extends AppRoute {
    href: string,
}

export const TokenService = (() => {

    /**
     * token缓存工具对象
     * @author  韦胜健
     * @date    2021/5/14 15:50
     */
    const cache = (() => {
        const STORAGE_KEY = 'O2_TOKEN_INFO'
        const get = () => {
            const text = window.localStorage.getItem(STORAGE_KEY)
            if (!text) {
                return null
            }
            try {
                return JSON.parse(text) as iToken
            } catch (e) {
                return null
            }
        }
        const set = (info: iToken) => {
            window.localStorage.setItem(STORAGE_KEY, JSON.stringify(info))
        }
        return {get, set}
    })();

    /**
     * prevPage缓存工具对象
     * @author  韦胜健
     * @date    2021/5/14 15:49
     */
    const prevRouteCache = (() => {
        const PREV_ROUTE_CACHE_KEY = '@@PREV_ROUTE_CACHE_KEY'
        const get = (): null | CacheRouteData => {
            const text = window.localStorage.getItem(PREV_ROUTE_CACHE_KEY)
            if (!text) {
                return null
            } else {
                return JSON.parse(text)
            }
        }
        const cache = () => {
            let cacheData: CacheRouteData = {
                ...nav.getRoute(),
                href: window.location.href,
            }
            window.localStorage.setItem(PREV_ROUTE_CACHE_KEY, JSON.stringify(cacheData))
        }
        return {
            get,
            cache,
        }
    })();

    const state = {token: cache.get()}

    /**
     * 打开登录页面，记录prevPage
     * 刷新之后如果解析到url中带access_token，则走prevPage
     * @author  韦胜健
     * @date    2021/5/14 15:49
     */
    const login = () => {
        prevRouteCache.cache()
        const path = `${env.loginURL}${env.loginURL.charAt(env.loginURL.length - 1) === '/' ? '' : '/'}oauth/oauth/authorize?${qs.stringify({
            client_id: "o2-design",
            redirect_uri: window.location.origin + window.location.pathname,
            response_type: "token",
        })}`
        // console.log(path)
        // window.open(path)
        window.location.href = path
    }

    /**
     * 获取token
     * 如果token生效或者没有，打开登录页面获取token
     * @author  韦胜健
     * @date    2021/5/14 15:48
     */
    const getToken = async (forceUpdate?: boolean) => {
        if (!state.token || forceUpdate) {
            throw login()
        }
        if (Number(state.token.expires_in) * 1000 + Number(state.token.now) > Date.now()) {
            throw login()
        }
        return `${state.token.token_type} ${state.token.access_token}`
    }

    /**
     * 更新token
     * @author  韦胜健
     * @date    2021/5/14 15:48
     */
    const update = (token: iToken) => {
        state.token = token
        cache.set(token)
    }

    /**
     * 解析path：
     * 如果path的规则匹配正则 access_token，则判断缓存中有没有prevPage，有就打开prevPage，没有就返回null，走DEFAULT_PATH
     * 如果不匹配，返回传入的path，不做任何处理
     * @author  韦胜健
     * @date    2021/5/14 15:47
     */
    const getPrevPageFromTokenInfo = (route: AppRoute): AppRoute | null => {
        if (/access_token=.*/.test(route.path)) {
            const tokenInfo = qs.parse(route.path)
            update(tokenInfo as any)
            notification.success({message: '登录提示', description: '已经更新用户登录信息。'})
            const cacheRoute = prevRouteCache.get()
            if (!!cacheRoute) {
                if (cacheRoute.href.indexOf('debug.html') > -1) {
                    window.location.href = cacheRoute.href
                } else {
                    window.location.hash = buildHref(cacheRoute)
                }
            }
            return cacheRoute
        } else {
            return route
        }
    }

    return {
        getPrevPageFromTokenInfo,
        getToken,
        state,
    }
})();
