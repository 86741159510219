import fullEmoji from 'markdown-it-emoji/lib/data/full.json'
import {buildAssetsPath} from "../../env/env";

const Prism = (window as any).$Prism

const md = new markdownit({
    html: true,
    linkify: true,
    typographer: true,
    xhtmlOut: true,
    // breaks: true,
    langPrefix: true,

    highlight: function (str: string, lang: string) {
        if (lang && Prism.languages[lang] && lang !== 'none') {
            try {
                str = str.replace(/\\`\\`\\`/g, '```').replace(/\\:\\:\\:/g, ':::')
                return `<div class="pl-prism"><pre class="line-numbers"><code class="language-${lang}">${md.utils.escapeHtml(str)}</code></pre></div>`
            } catch (e) {
                console.error(e)
                return 'Render Prism Error!'
            }
        }

        return '<pre class="hljs"><code>' + md.utils.escapeHtml(str) + '</code></pre>';
    }

})

/**
 * 自定义代码块
 * @author  韦胜健
 * @date    2020/9/13 8:09
 */
const blocks = [
    {tag: 'tip', title: '提示'},
    {tag: 'success', title: '提示'},
    {tag: 'warn', title: '注意'},
    {tag: 'error', title: '注意'},
];

blocks.forEach(block => {
    const blockTagReg = new RegExp(`^\\s*${block.tag}\\s*`)
    md.use(MarkdownItContainer, block.tag, {
        render(tokens: any[], idx: number) {
            const token = tokens[idx]
            if (token.nesting === 1) {
                // opening tag
                const _title = token.info.replace(blockTagReg, '').trim()
                const title = _title || block.title
                return `<div class="${block.tag} custom-block"> <p class="block-title">${title}</p>`;
            } else {
                // closing tag
                return '</div>\n';
            }
        },
    })
})

md.use(markdownItAttrs, {});
md.use(markdownitEmoji)

md.renderer.rules.emoji = function (token: string, idx: number) {
    // @ts-ignore
    return `<span class="emoji emoji_${token[idx].markup}">${fullEmoji[token[idx].markup]}</span>`;
};

export function renderMarkdown(markdownString: string | undefined, el: HTMLElement): void {
    let innerHtml = !!markdownString ? md.render(markdownString) : null
    if (!!innerHtml) {
        innerHtml = (innerHtml.replace(/<a(.*?)>(.*?)<\/a>/g, `<a$1><span>$2</span><i class="pl-icon-font pli-link"></i></a>`) as string)
            .replace(/<img src="(.*?)".*>/g, (...match) => {
                let [, src] = match
                src = /https?:\/\//.test(src) ? src : buildAssetsPath(src)
                return `<div class="pl-markdown-img"><img src="${src}"></div>`
            })
        el.innerHTML = innerHtml
        Prism.highlightAllUnder(el)
    } else {
        el.innerHTML = innerHtml
    }
}

export function escapeHtml(str: string) {
    return md.utils.escapeHtml(str);
}
