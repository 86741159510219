import {designComponent, PropType, reactive, useClasses, watch} from "o2-design";
import {MdcCase} from "./process/processCaseString";
import {processHtmlString} from "./process/processHtmlString";
import {delay} from "plain-utils/utils/delay";
import React from "react";
import {O2Markdown} from "../markdown/Markdown";
import {openDebugPage} from "../../debug/openDebugPage";
import {nav} from "../nav";

export const MdcCaseComponent = designComponent({
    props: {
        mdc: {type: Object as PropType<MdcCase>, required: true},
        onlyCase: {type: Boolean},
    },
    setup({props}) {

        const state = reactive({
            Component: null as any,
            style: null as null | string,

            showCode: props.mdc.expand,
            isInitCollapse: props.mdc.expand,
        })

        const classes = useClasses(() => {
            return [
                'pl-mdc-case',
                {
                    'pl-mdc-case-show-code': state.showCode,
                }
            ]
        })

        watch(() => props.mdc, async val => {
            const {jsx, css} = val;
            Object.assign(window, {jsx, css} as any)

            if (!jsx) {
                state.Component = null
            } else {
                const decode = await processHtmlString(jsx, css)
                if (!decode) {
                    return state.Component = null
                }
                const {script, style} = decode
                Object.assign(state, {Component: script, style})
            }
        }, {immediate: true})

        const handler = {
            toggleCode: async () => {
                const flag = !state.showCode
                // console.log(props.mdc.index)
                if (flag) {
                    if (!state.isInitCollapse) {
                        state.isInitCollapse = true
                        await delay(23)
                    }
                    state.showCode = true
                } else {
                    state.showCode = false
                }
            },
            openRunPage: () => {
                // $$notice.warn('待完成')
                openDebugPage(nav.state.route.path, props.mdc.index)
            }
        }

        return {
            render: () => {

                const {Component, style} = state

                const Case = !Component ? null : (
                    <div className={'pl-mdc-case-wrapper' + (props.mdc.space ? ' pl-mdc-case-space' : '')}>
                        <Component/>
                        {!!style && (
                            <style>
                                {style}
                            </style>
                        )}
                    </div>
                )

                if (props.onlyCase) {
                    return Case
                }

                return (
                    <div className={classes.value}>
                        {!!props.mdc.title && (
                            <h2>
                                <span>{props.mdc.title == `''` ? '' : props.mdc.title}</span>
                                <div className="pl-mdc-case-operator">
                                    <span className="pl-mdc-case-close-code-btn" onClick={handler.openRunPage}>
                                        <span>在线调试</span>
                                        {/*<PlIcon icon="el-icon-debug-s"/>*/}
                                    </span>
                                    <span onClick={handler.toggleCode} className="pl-mdc-case-close-code-btn">
                                        <span>{state.showCode ? '收起代码' : '查看代码'}</span>
                                        {/*<PlIcon icon="el-icon-d-arrow-left"/>*/}
                                    </span>
                                </div>
                            </h2>
                        )}
                        {!!props.mdc.desc && (<O2Markdown md={props.mdc.desc}/>)}
                        {Case}
                        {!!props.mdc.bottomDesc && (<div className="pl-mdc-bottom-desc"><O2Markdown md={props.mdc.bottomDesc}/></div>)}
                        {!!state.isInitCollapse && state.showCode && (
                            <div>
                                {!!props.mdc.content && (
                                    <O2Markdown md={props.mdc.content}/>
                                )}
                                {!!Component && <>
                                    <O2Markdown md={"```jsx\n" + props.mdc.jsx + "\n```"} className="pl-mdc-case-code"/>
                                    {!!props.mdc.css && <O2Markdown md={"```css\n" + props.mdc.css + "\n```"} className="pl-mdc-case-code"/>}
                                </>}
                                <div className="pl-mdc-case-close-code-btn-wrapper">
                                    <div className="pl-mdc-case-close-code-btn" onClick={handler.toggleCode}>
                                        <span>收起代码</span>
                                        {/*<PlIcon icon="el-icon-d-arrow-left"/>*/}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )
            }
        }

    },
})
